import { useContext } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import {
  BlockchainDataHandlerInterface,
  ParsedTransactionInterface,
  PoolBlockchainAccountValues,
  PoolBlockchainValues,
  TransactionStatus,
} from './interfaces';
import { PoolStatus } from '../../context/states/ListState/interfaces';
import { Address, RequestTypes, SystemIDs, WithdrawalTypes } from '../../../constants';
import { TransactionActionTypes, TransactionStateContext } from '../../context/states/PendingTransactionsState';
import { transformNumber } from '../../../utils';
import { httpRequest } from '../../../services';

import abiPoolFactory from '../../../contracts/abi/PoolFactory.json';
import abiIERC20Factory from '../../../contracts/abi/IERC20.json';
import abiIERC20MetaDataFactory from '../../../contracts/abi/IERC20Metadata.json';
import abiPool from '../../../contracts/abi/Pool.json';
import abiBlendedPool from '../../../contracts/abi/BlendedPool.json';
import { BlockchainErrorMessages, getBlockchainErrorMessage } from '../../../constants/blockchainErrorMessages';

export const BlockchainDataHandler = (): BlockchainDataHandlerInterface => {
  const { active, account, library } = useWeb3React();
  const { dispatchTransactionState } = useContext(TransactionStateContext);

  const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
  const CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;
  const BP_ADDRESS = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;

  const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_NETWORK_URL);
  const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, provider);
  const IERC20MetaData = new ethers.Contract(CONTRACT_USDC, abiIERC20MetaDataFactory, provider);
  const blendedPoolContract = new ethers.Contract(BP_ADDRESS, abiBlendedPool, provider);

  const getAccountBalance = async (): Promise<{
    stableCoin: { value: number; symbol: string };
    eth: { value: number; symbol: string };
  }> => {
    const signer = library.getSigner();

    const decimals = await IERC20MetaData.decimals();
    const scBalance = await IERC20MetaData.balanceOf(signer.getAddress());
    const symbol = await IERC20MetaData.symbol();

    const stableCoinBalance = transformNumber(scBalance, decimals, true);
    const ethBalance = transformNumber(await signer.getBalance(), 18, true);
    const ethSymbol = process.env.REACT_APP_CHAIN_SYMBOL || 'ETH';

    return {
      stableCoin: {
        value: Number(parseFloat(`${stableCoinBalance}`).toFixed(2)),
        symbol,
      },
      eth: {
        value: Number(parseFloat(`${ethBalance}`).toFixed(2)),
        symbol: ethSymbol,
      },
    };
  };

  const getPool = async (poolId: string): Promise<PoolBlockchainValues> => {
    if (poolId === SystemIDs.BlendedPool) {
      try {
        const [poolInfo, decimals, symbol, totalMinted] = await Promise.all([
          blendedPoolContract.poolInfo(),
          blendedPoolContract.decimals(),
          IERC20MetaData.symbol(),
          IERC20MetaData.balanceOf(BP_ADDRESS),
        ]);

        const { lockupPeriod, minInvestmentAmount } = poolInfo;

        // Blended pool does not have a pool size (technically it does, but it's as high as possible, so it's not useful to display it)
        const poolSize = 0;
        const minInvestmentSize = transformNumber(minInvestmentAmount, decimals, true);
        const totalDeposited = transformNumber(totalMinted, decimals, true);

        return {
          poolId: SystemIDs.BlendedPool,
          poolAddress: BP_ADDRESS,
          poolSize,
          minInvestmentSize,
          lockupPeriod: lockupPeriod.toNumber() / 86400,
          totalDeposited,
          symbol,
          poolStatus: PoolStatus.Active,
        };
      } catch (error) {
        return {} as PoolBlockchainValues;
      }
    } else {
      try {
        if (poolId) {
          const address = await poolFactory.pools(poolId);

          if (address === Address.Null) {
            return {
              poolStatus: PoolStatus.NotExist,
            } as PoolBlockchainValues;
          }
          // Create Pool contract object
          const pool = new ethers.Contract(address, abiPool, provider);

          if (!pool) {
            return {
              poolStatus: PoolStatus.NotExist,
            } as PoolBlockchainValues;
          }

          const [poolInfo, decimals, poolState, symbol, totalSupply] = await Promise.all([
            pool.poolInfo(),
            pool.decimals(),
            pool.poolState(),
            IERC20MetaData.symbol(),
            // total supply of the Helios tokens serves as the total deposited amount
            pool.totalSupply(),
          ]);

          let poolStatus: PoolStatus;
          const { lockupPeriod, investmentPoolSize, minInvestmentAmount } = poolInfo;

          switch (poolState) {
            case 0:
              poolStatus = PoolStatus.Active;
              break;
            case 1:
              poolStatus = PoolStatus.Closed;
              break;
            default:
              poolStatus = PoolStatus.Error;
              break;
          }

          const poolSize = transformNumber(investmentPoolSize, decimals, true);
          const minInvestmentSize = transformNumber(minInvestmentAmount, decimals, true);
          const totalDeposited = transformNumber(totalSupply, decimals, true);

          return {
            poolId,
            poolAddress: address,
            poolSize,
            minInvestmentSize,
            lockupPeriod: lockupPeriod.toNumber() / 86400,
            totalDeposited,
            symbol,
            poolStatus,
          };
        }

        return {
          poolStatus: PoolStatus.NotExist,
        } as PoolBlockchainValues;
      } catch (error) {
        return {
          poolStatus: PoolStatus.Error,
        } as PoolBlockchainValues;
      }
    }
  };

  const getAccountPool = async (poolId: string): Promise<PoolBlockchainAccountValues> => {
    const signer = library.getSigner();

    if (account && poolId) {
      if (poolId === SystemIDs.BlendedPool) {
        try {
          const [yearnedYield, decimals, availableToWithdraw, balanceOf] = await Promise.all([
            blendedPoolContract.yields(account),
            blendedPoolContract.decimals(),
            blendedPoolContract.unlockedToWithdraw(account),
            blendedPoolContract.balanceOf(account),
          ]);
          return {
            yearnedYield: transformNumber(yearnedYield, decimals, true),
            availableToWithdraw: transformNumber(availableToWithdraw, decimals, true),
            investedAmount: transformNumber(balanceOf, decimals, true),
            isInvested: balanceOf.gt(0),
            accountPoolBalance: transformNumber(yearnedYield.add(balanceOf), decimals, true),
          } as PoolBlockchainAccountValues;
        } catch (error) {
          console.log('Blended pool data fetching Error:', error);
          return {} as PoolBlockchainAccountValues;
        }
      } else {
        try {
          const address = await poolFactory.pools(poolId);
          if (address === Address.Null) {
            return {} as PoolBlockchainValues;
          }
          // Create Pool contract object
          const pool = new ethers.Contract(address, abiPool, signer);
          if (!pool) {
            return {} as PoolBlockchainValues;
          }

          const [yearnedYield, decimals, availableToWithdraw, balanceOf] = await Promise.all([
            pool.yields(account),
            pool.decimals(),
            pool.unlockedToWithdraw(account),
            pool.balanceOf(account),
          ]);
          return {
            yearnedYield: transformNumber(yearnedYield, decimals, true),
            availableToWithdraw: transformNumber(availableToWithdraw, decimals, true),
            investedAmount: transformNumber(balanceOf, decimals, true),
            isInvested: balanceOf.gt(0),
            accountPoolBalance: transformNumber(yearnedYield.add(balanceOf), decimals, true),
          } as PoolBlockchainAccountValues;
        } catch (error) {
          console.log('Pool data fetching Error:', error);
          return {} as PoolBlockchainAccountValues;
        }
      }
    } else {
      return {} as PoolBlockchainAccountValues;
    }
  };

  const getRepaymentList = async (poolAddress: string): Promise<ParsedTransactionInterface[]> => {
    try {
      if (account) {
        const abiInterface = new ethers.utils.Interface(abiPool);
        const url = `${process.env.REACT_APP_EXPLORER_HISTORY_API}=${poolAddress}`;
        const response = await httpRequest({ url, method: RequestTypes.Get, withoutToken: true });
        // Take the first 10 characters of the hash as the method ID

        const decimals = await IERC20MetaData.decimals();
        const symbol = await IERC20MetaData.symbol();

        const paymentHistory = response?.data.result.filter(
          (tx: { functionName: string }) => tx.functionName === 'repay(uint256 loanId)',
        );
        const repaymentHistory = paymentHistory.map((tx: any) => {
          const txValue = abiInterface.decodeFunctionData('repay', tx.input);
          return {
            txHash: tx.hash,
            txDate: new Date(Number(tx.timeStamp || 0) * 1000),
            method: 'repay',
            value: Number(ethers.utils.formatUnits(txValue[0], decimals)),
            from: tx.from,
            symbol,
          };
        });
        return repaymentHistory || [];
      }
      return [];
    } catch (error) {
      console.log('Transaction history data fetching Error:', error);
      return [];
    }
  };

  const withdraw = async (poolId: string, amount: number, type: WithdrawalTypes): Promise<TransactionStatus> => {
    const signer = library.getSigner();
    const signerAddress = await signer.getAddress();

    console.log('Withdrawal:', poolId, amount, type);

    if (poolId && active) {
      try {
        const poolAddress = poolId === SystemIDs.BlendedPool ? BP_ADDRESS : await poolFactory.pools(poolId);
        const decimals = await IERC20MetaData.decimals();

        // default pool
        let pool = new ethers.Contract(BP_ADDRESS, abiBlendedPool, signer);

        if (poolId !== SystemIDs.BlendedPool) {
          pool = new ethers.Contract(poolAddress, abiPool, signer);
        }

        const amountWad = ethers.utils.parseUnits(amount.toString(), decimals);

        if (poolAddress === Address.Null) {
          console.log('Pool address not found');
          return {
            status: false,
            isPool: false,
            errorMessage: getBlockchainErrorMessage(BlockchainErrorMessages.POOL_NOT_FOUND),
          };
        }

        if (!pool) {
          console.log('Pool not found');
          return {
            isPool: false,
            status: false,
            errorMessage: getBlockchainErrorMessage(BlockchainErrorMessages.POOL_NOT_FOUND),
          };
        }

        if (type === WithdrawalTypes.Investment) {
          const withdrawTx = await pool.withdraw(signerAddress, amountWad);
          dispatchTransactionState({
            type: TransactionActionTypes.SetTransaction,
            payload: {
              hash: withdrawTx.hash,
              description: 'Withdrawing funds',
            },
          });

          const withdrawReceipt = await withdrawTx.wait();
          dispatchTransactionState({
            type: TransactionActionTypes.DeleteTransaction,
            payload: {
              hash: withdrawTx.hash,
            },
          });

          if (withdrawReceipt.status === 1) {
            return {
              status: true,
            };
          }
        } else {
          console.log('Withdrawing Yield');
          const withdrawYieldTx = await pool.functions['withdrawYield(address)'](signerAddress);

          dispatchTransactionState({
            type: TransactionActionTypes.SetTransaction,
            payload: {
              hash: withdrawYieldTx.hash,
              description: 'Withdrawing Yield',
            },
          });
          const withdrawFundsReceipt = await withdrawYieldTx.wait();
          dispatchTransactionState({
            type: TransactionActionTypes.DeleteTransaction,
            payload: {
              hash: withdrawYieldTx.hash,
            },
          });

          if (withdrawFundsReceipt.status === 1) {
            return {
              status: true,
            };
          }
        }
      } catch (error: any) {
        console.error('Withdraw error?:', error);
        return {
          status: false,
          errorMessage: getBlockchainErrorMessage(error.code),
        };
      }
    }
    return {
      isPool: false,
      status: false,
    };
  };

  const invest = async (poolId: string, amount: number): Promise<TransactionStatus> => {
    try {
      if (!active || !poolId) {
        return {
          isPool: false,
          status: false,
        };
      }

      const signer = library.getSigner();
      const IERC20WithSigner = new ethers.Contract(CONTRACT_USDC, abiIERC20Factory, signer);
      const decimals = await IERC20MetaData.decimals();

      let pool = new ethers.Contract(BP_ADDRESS, abiBlendedPool, signer);
      const amountWad = ethers.utils.parseUnits(amount.toString(), decimals);

      let approveTx;
      let depositTx;

      if (poolId === SystemIDs.BlendedPool) {
        approveTx = await IERC20WithSigner.approve(BP_ADDRESS, amountWad);

        dispatchTransactionState({
          type: TransactionActionTypes.SetTransaction,
          payload: {
            hash: approveTx.hash,
            description: 'Approving',
          },
        });

        const approveReceipt = await approveTx.wait();
        dispatchTransactionState({
          type: TransactionActionTypes.DeleteTransaction,
          payload: {
            hash: approveTx.hash,
          },
        });

        if (approveReceipt.status === 1) {
          depositTx = await pool.deposit(amountWad);
        }
      } else {
        const poolAddress = await poolFactory.pools(poolId);
        pool = new ethers.Contract(poolAddress, abiPool, signer);

        if (poolAddress === Address.Null) {
          return {
            isPool: false,
            status: false,
            errorMessage: getBlockchainErrorMessage(BlockchainErrorMessages.POOL_NOT_FOUND),
          };
        }

        if (!pool) {
          return {
            isPool: false,
            status: false,
            errorMessage: getBlockchainErrorMessage(BlockchainErrorMessages.POOL_NOT_FOUND),
          };
        }

        approveTx = await IERC20WithSigner.approve(poolAddress, amountWad);

        dispatchTransactionState({
          type: TransactionActionTypes.SetTransaction,
          payload: {
            hash: approveTx.hash,
            description: 'Approving',
          },
        });

        const approveReceipt = await approveTx.wait();
        dispatchTransactionState({
          type: TransactionActionTypes.DeleteTransaction,
          payload: {
            hash: approveTx.hash,
          },
        });

        if (approveReceipt.status === 1) {
          depositTx = await pool.deposit(amountWad);
        }
      }

      if (depositTx) {
        dispatchTransactionState({
          type: TransactionActionTypes.SetTransaction,
          payload: {
            hash: depositTx.hash,
            description: 'Depositing',
          },
        });

        const depositReceipt = await depositTx.wait();

        dispatchTransactionState({
          type: TransactionActionTypes.DeleteTransaction,
          payload: {
            hash: depositTx.hash,
          },
        });

        if (depositReceipt.status === 1) {
          return {
            status: true,
          };
        }
      }

      return {
        status: false,
        errorMessage: getBlockchainErrorMessage(BlockchainErrorMessages.NETWORK_ERROR),
      };
    } catch (error: any) {
      console.error('Invest error?:', error.code);
      return {
        status: false,
        errorMessage: getBlockchainErrorMessage(error.code),
      };
    }
  };

  return {
    getPool,
    getAccountPool,
    invest,
    withdraw,
    getAccountBalance,
    getRepaymentList,
  };
};
