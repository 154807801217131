import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlockchainDataHandler } from '../BlockchainDataHandler';
import { AppLoaderActionTypes, AppModalActionTypes, ConfigStateContext } from '../../context/states/ConfigState';
import { PoolInterface } from '../../context/states/ListState/interfaces';
import { ModalInfoTypes, SystemIDs, WithdrawalTypes } from '../../../constants';
import { ListDataHandler } from '../ListDataHandler';

interface UseBlockchainOperationsProps {
  value: number;
  pool: PoolInterface | undefined;
}

interface UseBlockchainWithdrawProps extends UseBlockchainOperationsProps {
  type: WithdrawalTypes;
}

export const useBlockchainInvest = ({ value, pool }: UseBlockchainOperationsProps) => {
  const { invest } = BlockchainDataHandler();
  const { getBlockchainPool } = ListDataHandler();
  const { appModal, dispatchAppModal, dispatchAppLoader } = useContext(ConfigStateContext);

  const navigate = useNavigate();
  const handleInvest = useCallback(async () => {
    const poolIdTo = pool?.blockchainData?.poolId || SystemIDs.BlendedPool;

    if (!value) {
      return {
        status: false,
        errorMessage: 'No value provided',
        value,
        navigateFunction: () => navigate('/pools'),
      };
    }

    try {
      dispatchAppModal({ type: AppModalActionTypes.Close });
      dispatchAppLoader({
        type: AppLoaderActionTypes.Open,
        payload: {
          description: 'Please wait, processing your request...',
        },
      });

      console.log(poolIdTo, value);

      const { status, errorMessage } = await invest(poolIdTo, value);

      if (appModal.isOpen) {
        dispatchAppModal({ type: AppModalActionTypes.Close });
        dispatchAppLoader({ type: AppLoaderActionTypes.Close });
      }

      if (status) {
        dispatchAppLoader({
          type: AppLoaderActionTypes.Open,
          payload: {
            description: 'Please wait, updating data...',
          },
        });

        dispatchAppLoader({ type: AppLoaderActionTypes.Close });

        dispatchAppModal({
          type: AppModalActionTypes.InfoModal,
          payload: {
            cancelCallBack: () => {
              navigate('/pools');
              dispatchAppModal({ type: AppModalActionTypes.Close });
            },
            info: {
              type: ModalInfoTypes.Success,
              value,
              description:
                'Thank you very much for your investment!, please click the button below to return to your dashboard',
              label: 'Deposited Successfully',
            },
          },
        });
        await getBlockchainPool(poolIdTo);
      } else {
        dispatchAppModal({
          type: AppModalActionTypes.InfoModal,
          payload: {
            cancelButtonText: 'Return to dashboard',
            cancelCallBack: () => {
              navigate('/pools');
              dispatchAppModal({ type: AppModalActionTypes.Close });
            },
            info: {
              type: ModalInfoTypes.Fail,
              value,
              description: errorMessage,
              label: 'Deposit Failed',
            },
          },
        });
      }

      return {
        status: false,
        errorMessage: 'errorMessage',
        value,
        navigateFunction: () => navigate('/pools'),
      };
    } catch (error) {
      console.error('Operation error:', error);
      return {
        status: false,
        errorMessage: 'Operation error',
        value,
        navigateFunction: () => navigate('/pools'),
      };
      // Handle errors
    }
  }, [value, invest]);

  return { handleInvest };
};

export const useBlockchainWithdraw = ({ value, pool, type }: UseBlockchainWithdrawProps) => {
  const { withdraw } = BlockchainDataHandler();
  const { getBlockchainPool } = ListDataHandler();
  const { appModal, dispatchAppModal, dispatchAppLoader } = useContext(ConfigStateContext);
  const navigate = useNavigate();

  const handleWithdraw = useCallback(async () => {
    const poolIdFrom = pool?.blockchainData?.poolId || SystemIDs.BlendedPool;

    if (!value) return;

    try {
      dispatchAppModal({ type: AppModalActionTypes.Close });
      dispatchAppLoader({
        type: AppLoaderActionTypes.Open,
        payload: {
          description: 'Please wait, processing your request...',
        },
      });

      const { status, errorMessage } = await withdraw(poolIdFrom, value, type);

      if (appModal.isOpen) {
        dispatchAppModal({ type: AppModalActionTypes.Close });
        dispatchAppLoader({ type: AppLoaderActionTypes.Close });
      }

      if (status) {
        dispatchAppLoader({
          type: AppLoaderActionTypes.Open,
          payload: {
            description: 'Please wait, updating data...',
          },
        });

        dispatchAppLoader({ type: AppLoaderActionTypes.Close });

        dispatchAppModal({
          type: AppModalActionTypes.InfoModal,
          payload: {
            cancelCallBack: () => {
              navigate('/pools');
              dispatchAppModal({ type: AppModalActionTypes.Close });
            },
            info: {
              type: ModalInfoTypes.Success,
              value,
              description:
                'Thank you very much for your withdrawal!, please click the button below to return to your dashboard',
              label: 'Withdrawn Successfully',
            },
          },
        });
        await getBlockchainPool(poolIdFrom);
      } else {
        dispatchAppModal({
          type: AppModalActionTypes.InfoModal,
          payload: {
            cancelButtonText: 'Return to dashboard',
            cancelCallBack: () => {
              navigate('/pools');
              dispatchAppModal({ type: AppModalActionTypes.Close });
            },
            info: {
              type: ModalInfoTypes.Fail,
              value,
              description: errorMessage,
              label: 'Withdrawal Failed',
            },
          },
        });
      }

      // eslint-disable-next-line consistent-return
      return {
        status,
        errorMessage,
        value,
        navigateFunction: () => navigate('/pools'),
      };
    } catch (error) {
      console.error('Operation error:', error);
      // eslint-disable-next-line consistent-return
      return {
        status: false,
        errorMessage: 'Operation error',
        value,
        navigateFunction: () => navigate('/pools'),
      };
    }
  }, [value, withdraw]);

  return { handleWithdraw };
};
