import { HelElement, HelFC } from '../../interfaces';
import { InvestmentCardBoxProps } from './interfaces';
import { InvestmentCard } from '../cards';
import { PoolLink } from '../../components/shared/PoolLink';

export const InvestmentCardBox: HelFC<InvestmentCardBoxProps> = ({ pools }): HelElement => (
  <div className="investment-section">
    <div className="investment-cards-box">
      {
        pools.map((pool, index) => (
          <div key={`investment-card-${index}`}>
            <PoolLink id={pool.id as string}>
              <InvestmentCard
                className={!pool.blockchainAccountData?.isInvested ? 'not-invested' : ''}
                pool={pool}
              />
            </PoolLink>
          </div>
        ))
      }
    </div>
  </div>
);
