import { ItemType } from '../interfaces/dataListInterfaces/DataParamsInterface';
import { ConfigItemNameTypes, ListNameTypes } from '../constants';
import { PlatformInterface } from '../store/context/states/PlatformState/interfaces';
import { ListInterface } from '../store/context/states/ListState/interfaces';

interface LocalConfigItemInterface {
  platform?: PlatformInterface,
}

const initialLocalConfigListState: ListInterface = {
  pools: [],
  projects: [],
};

const initialLocalConfigItemState: LocalConfigItemInterface = {};

export const LocalConfig = class {
  static getLists = (): ListInterface => {
    const configLists: string = localStorage.getItem('configLists') || JSON.stringify(initialLocalConfigListState);
    return JSON.parse(configLists) as ListInterface;
  };

  static getListItems(name: ListNameTypes): ItemType[] {
    return this.getLists()[name];
  }

  static setLists(list: ListInterface): void {
    const currentConfigLists: ListInterface = this.getLists();
    const newConfigList: ListInterface = {
      ...currentConfigLists,
      ...list,
    };
    localStorage.setItem('configLists', JSON.stringify(newConfigList));
  }

  static getItem = (name: ConfigItemNameTypes): LocalConfigItemInterface => {
    const configItem: string = localStorage.getItem(name) || JSON.stringify(initialLocalConfigItemState);
    return JSON.parse(configItem) as LocalConfigItemInterface;
  };

  static setItem(name: ConfigItemNameTypes | string, value: ItemType): void {
    const currentConfigItem: LocalConfigItemInterface = this.getItem(name as ConfigItemNameTypes);
    if (name) {
      const newConfigItem: LocalConfigItemInterface = {
        ...currentConfigItem,
        ...value,
      };
      localStorage.setItem(name, JSON.stringify(newConfigItem));
    }
  }
};
