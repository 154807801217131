import { TransactionStateInterface, TransactionStateReducerInterface } from './interfaces';
import { currentTransactionInitialState } from './initialState';
import { CurrentTransactionActionTypes } from './actions';

export const transactionStateReducer: TransactionStateReducerInterface = (
  state = { ...currentTransactionInitialState },
  action,
): TransactionStateInterface => {
  switch (action.type) {
    case CurrentTransactionActionTypes.Update:
      return { ...state, ...action.payload };

    case CurrentTransactionActionTypes.Reset:
      return { ...currentTransactionInitialState };

    default:
      return state;
  }
};
