export enum ButtonTypes {
  Submit = 'submit',
  Button = 'button',
  Reset = 'reset',
}

export enum ColorVariablesTypes {
  Default = 'var(--color-primary)',
  Primary = 'var(--color-primary)',
  Secondary = 'var(--color-primary)',
}

export enum ColorTypes {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum CategoryTypes {
  Outlined = 'outlined',
  Filled = 'filled',
  Disabled = 'disabled',
  Default = 'default',
}

export enum SizeTypes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ModalTypes {
  MessageModal = 'MessageModal',
  DepositModal = 'DepositModal',
  InfoModal = 'InfoModal',
  InformModal = 'InformModal',
  TransactionModal = 'TransactionModal',
}

export enum ModalInfoTypes {
  Success = 'success',
  Fail = 'fail',
}

export enum CurrencyTypes {
  USDC = '$',
}

export enum CurrentTransactionType {
  WithdrawInvestment = 'WithdrawInvestment',
  WithdrawYield = 'WithdrawYield',
  InvestFunds = 'Invest',
  InvestYield = 'Reinvest',
}
