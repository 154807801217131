/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Link, useSearchParams } from 'react-router-dom';
import { HelElement, HelFC } from '../../interfaces';
import { CategoryTypes } from '../../constants';
import { ButtonBase, Spinner } from '../../components';
import { AccountStateContext, TransactionStateContext } from '../../store';
import connectedIcon from '../../assets/images/connected-icon.svg';
import shevronDownIcon from '../../assets/images/chevron-down.svg';
import copyIcon from '../../assets/images/copy-icon.svg';
import linkIcon from '../../assets/images/eth-link.svg';
import disconnectIcon from '../../assets/images/disconnect-icon.svg';
import { useCopyToClipboard } from '../../services';
import { isMetaMaskInstalled } from '../../utils';
import { useWeb3Connection } from '../../services/blockChain/hooks/useWeb3Connection';

export const Header: HelFC = ({ children }): HelElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    transactionState: { transactions },
  } = useContext(TransactionStateContext);

  const { account, deactivate, chainId } = useWeb3React();
  const { accountState } = useContext(AccountStateContext);
  const { balance } = accountState;
  const currentBalance = balance || {
    stableCoin: {
      value: 0,
      symbol: '',
    },
    eth: {
      value: 0,
      symbol: '',
    },
  };
  const { establishConnection } = useWeb3Connection();
  const [searchParams, setSearchParams] = useSearchParams();

  let address = '';
  if (account) {
    address = `${account?.slice(0, 10)}...${account?.slice(-6)}`;
  }
  const [copy] = useCopyToClipboard();
  const [copiedOpen, setCopiedOpen] = useState(false);

  useEffect(() => {
    if (isMetaMaskInstalled()) {
      window.ethereum.on('chainChanged', (id: string) => {
        if (Number(id).toString() !== process.env.REACT_APP_CHAIN_ID) {
          deactivate();
          setIsMenuOpen(false);
          searchParams.delete('isConnected');
          setSearchParams(searchParams);
          localStorage.setItem('isWalletConnected', 'false');
        }
      });
    } else {
      console.log('MetaMask is not installed');
    }
  }, [chainId]);
  const handleCopyClick = async () => {
    await copy(account as string).then(result => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 1000);
      }
    });
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.getElementById('account-menu')?.focus();
    }
  }, [isMenuOpen]);

  const disconnect = async () => {
    try {
      deactivate();
      setIsMenuOpen(false);
      searchParams.delete('isConnected');
      setSearchParams(searchParams);

      localStorage.setItem('isWalletConnected', 'false');
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleDropdownClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const addTokenToMetamask = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.REACT_APP_CONTRACT_USDC,
            decimals: 6,
          },
        },
      });
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className={`header ${account ? 'connected' : ''} `}>
      <Link to="/pools">
        <div className="header-logo" />
      </Link>
      <div className="spinner-block">
        {!!transactions.length &&
          transactions.map(transaction => <Spinner text={transaction.description} color="#DC5A5A" />)}
      </div>
      {!account ? (
        <div className="links-block">
          <ButtonBase
            className="button-without-icon"
            label="Connect Wallet"
            category={CategoryTypes.Filled}
            onClick={establishConnection}
          />
        </div>
      ) : (
        <div className="links-block">
          <div className="account-dropdown">
            <ButtonBase
              className="button-with-icon button-connected w-full"
              category={CategoryTypes.Default}
              onClick={handleDropdownClick}
            >
              <img src={connectedIcon} alt="connected" />
              <span className="label">{address}</span>
              <img src={shevronDownIcon} alt="downIcon" />
            </ButtonBase>
            {isMenuOpen && (
              <div
                id="account-menu"
                className="account-menu button-connected"
                tabIndex={0}
                onBlur={event => {
                  if (!event.currentTarget.contains(event.relatedTarget)) {
                    setIsMenuOpen(false);
                  }
                }}
              >
                <div className="copy-address">
                  <img src={connectedIcon} alt="connected" loading="eager" />
                  <span className="label">{address}</span>
                  <button onClick={handleCopyClick}>
                    <img src={copyIcon} alt="copy" />
                  </button>
                  {copiedOpen && <div className="copy-popup">copied!</div>}
                </div>
                <div className="balance pl-[1.3rem] flex justify-between items-center">
                  <div className="flex items-center gap-1">
                    <h3>${currentBalance.stableCoin.value}</h3>
                    {currentBalance.stableCoin.symbol || ''}
                  </div>
                </div>
                <span
                  role="button"
                  className="w-full pl-[1.3rem] flex items-center cursor-pointer underline text-[#0040EA] text-[11px] font-primary-light mt-[-3px] mb-3"
                  title="Add to metamask"
                  onClick={addTokenToMetamask}
                >
                  Add to metamask
                </span>
                <div className="balance pl-[1.3rem] mb-3 flex items-center">
                  <h3>{currentBalance.eth.value}</h3>
                  {currentBalance.eth.symbol || ''}
                </div>
                <hr />
                <a
                  href={`${process.env.REACT_APP_TRANSACTION_EXPLORER_LINK}/address/${account}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ButtonBase className="button-with-icon" category={CategoryTypes.Default}>
                    <img src={linkIcon} alt="" loading="eager" />
                    <span className="label">Explore</span>
                  </ButtonBase>
                </a>
                <ButtonBase className="button-with-icon" category={CategoryTypes.Default} onClick={disconnect}>
                  <img src={disconnectIcon} alt="" loading="eager" />
                  <span className="label">Disconnect</span>
                </ButtonBase>
              </div>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
