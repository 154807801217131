import { useEffect } from 'react';
import { HelElement, HelFC } from '../../interfaces';
import { PageTitleBoxProps } from './interfaces';

export const PageTitleBox: HelFC<PageTitleBoxProps> = ({
  logo, title, description, video,
}): HelElement => {
  useEffect(() => {
    if (video) {
      const videoTag = document.getElementById('video') as HTMLMediaElement;
      if (videoTag) {
        videoTag.play().catch();
      }
    }
  });

  return (
    <div className="page-title">
      <div className="info-block">
        <h1>
          {title}
        </h1>
        <p className="description">{description}</p>
      </div>
      {!!logo && <img src={logo} alt="earth" />}
      {!!video && (
      <video src={video} autoPlay muted loop playsInline id="video" preload="none" />
      )}
    </div>
  );
};
