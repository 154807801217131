import { HelElement, HelFC } from '../../interfaces';
import { PoolCardProps } from './interfaces';
import { IconArrowSmallRight, PoolDetailBlock, ProgressBlock } from '../../components';
import { SystemIDs } from '../../constants';
import bpCover from '../../assets/images/bp-cover.jpg';

export const PoolCard: HelFC<PoolCardProps> = ({ title, description, image, className, details, id }): HelElement => {
  // Remove nullables from the details array to adapt the UI to the Blended Pool data, other pools will not be affected
  const detailsWithoutNullables = details.filter(item => item.value !== 0);

  const progressData = detailsWithoutNullables.find(({ currentValue }) => currentValue || currentValue === 0);

  return (
    <div className={`pool-card ${className || ''}`}>
      <div className="pool-card-image">
        {image && <img src={id === SystemIDs.BlendedPool ? bpCover : image} alt="pool 1" />}
      </div>
      <div className="pool-info">
        <div className="title">
          <h3>{title}</h3>
          <span className="description">{description}</span>
        </div>
        <div className={`pool-details ${className || ''}`}>
          {detailsWithoutNullables.map(({ value, label, suffix, prefix }, index) => (
            <>
              {label !== 'Pool size:' && (
                <div key={index}>
                  <PoolDetailBlock value={value} label={label as string} prefix={prefix} suffix={suffix} />
                </div>
              )}
            </>
          ))}
        </div>
        <div className="view-projects">
          {progressData ? (
            <ProgressBlock
              value={progressData.value}
              currentValue={progressData.currentValue}
              label={progressData.label}
              suffix={progressData.suffix}
            />
          ) : (
            <div className="invisible" />
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '15px',
            }}
          >
            <span
              style={{
                color: '#4F3EFF',
              }}
            >
              View Projects
            </span>
            <IconArrowSmallRight />
          </div>
        </div>
      </div>
    </div>
  );
};
