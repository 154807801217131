import { AccountInterface } from './interfaces';

export const accountInitialState: AccountInterface = {
  isVerified: false,
  balance: {
    eth: {
      value: 0,
      symbol: 'ETH',
    },
    stableCoin: {
      value: 0,
      symbol: 'USDC',
    },
  },
};
