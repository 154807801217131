// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';
import { HelElement, HelFC } from '../../interfaces';
import { ArticleBoxProps } from './interfaces';

export const ArticleBox: HelFC<ArticleBoxProps> = ({
  item: { descriptionTitle = '', description = '' },
  className,
}): HelElement => (
  <div className={`article-box ${className || ''}`}>
    <h3>{descriptionTitle || <div className="skeleton" />}</h3>
    <div className="pool-description w-[100%]">{ReactHtmlParser(description) || <div className="skeleton" />}</div>
  </div>
);
