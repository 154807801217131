import { useWeb3React } from '@web3-react/core';
import { DiscordCard } from '../cards';

export const DiscordBox = () => {
  const { account } = useWeb3React();

  return (
    <>
      {!account && (
      <div className="discord">
        <DiscordCard
          title="Join our DISCORD"
          description="We’ve built a community of solar experts, climate activists and experienced builders. Join today to learn how you can contribute in the fight against climate change!"
        />
      </div>
      )}
    </>
  );
};
