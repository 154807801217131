import { useContext } from 'react';
import { cloneDeep } from 'lodash';
import { AppModalActionTypes, ConfigStateContext, useCloseAppModal } from '../../store';
import { HelElement, HelFC } from '../../interfaces';
import closeIcon from '../../assets/images/close-icon.svg';
import arrowBackIcon from '../../assets/images/arrow-back.svg';
import { ModalBaseProps } from './interfaces';

export const ModalBase: HelFC<ModalBaseProps> = ({ className, children }): HelElement => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const closeModal = useCloseAppModal();

  const { title, content, description, isClosable, contentTypes = [], action } = appModal;

  const handleBack = () => {
    if (contentTypes.length) {
      const newContentTypes = cloneDeep(contentTypes);
      newContentTypes.pop();
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {},
      });
    }
  };

  return (
    <div className={`modal ${className}`}>
      <div className="modal-header">
        {!!contentTypes.length && (
          <button className="modal-back-icon" onClick={handleBack}>
            <img src={arrowBackIcon} alt="Back icon" />
          </button>
        )}

        <span className="space-element" />

        {isClosable && (
          <button className="modal-close-icon" onClick={closeModal}>
            <img src={closeIcon} alt="Close icon" />
          </button>
        )}
      </div>
      {!!title && <h3>{title}</h3>}
      {!!description && <p>{description}</p>}
      {content || children}
      {action && <div className="modal-action">{action}</div>}
    </div>
  );
};
