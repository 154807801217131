import { useContext, useEffect } from 'react';
import { blendedPoolMock } from '../../../../../constants/mockData';
import {
  AccountStateContext,
  AppModalActionTypes,
  ConfigStateContext,
  CurrentTransactionActionTypes,
  CurrentTransactionStateContext,
  ListStateContext,
} from '../../../../../store';
import { minEthRequired, SystemIDs } from '../../../../../constants';
import failIcon from '../../../../../assets/images/fail-icon.svg';
import { numberWithCommas } from '../../../../../utils';

interface WidgetItemProps {
  label: string;
  value: any;
  suffix: string;
}

const WidgetItem = ({ label, value, suffix }: WidgetItemProps) => (
  <div className="pool-detail-block">
    <div className="label">{label}</div>
    <div className="value-content">
      {value && <span className="value">{value}</span>}
      {suffix && <span className="symbol">{suffix}</span>}
    </div>
  </div>
);

/**
 * This component is used only when we reinvest yield.
 * Therefore, additional stable coin balance validation is not required in this component.
 */
export const InitiateBlendedPoolYieldInvest = () => {
  const { images, name, annualEmissionsAverted } = blendedPoolMock;
  const image = images?.[0]?.urls?.original;

  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const pool = appModal.data;
  const apy = pool?.apy;
  const holdPeriod = pool?.blockchainData?.lockupPeriod;
  const yearnedYield = pool?.blockchainAccountData?.yearnedYield || 0;
  const { transactionType } = appModal;
  const { dispatchTransactionState } = useContext(CurrentTransactionStateContext);
  const { accountState } = useContext(AccountStateContext);
  const { balance } = accountState;
  const listState = useContext(ListStateContext);
  const { pools } = listState.listState;
  const blendedPool = pools?.find(p => p.id === SystemIDs.BlendedPool);
  const blendedPoolThreshold = blendedPool?.blockchainData?.minInvestmentSize || 0;
  const currentBalance = balance || {
    stableCoin: {
      value: 0,
      symbol: '',
    },
    eth: {
      value: 0,
      symbol: '',
    },
  };

  useEffect(() => {
    console.log(transactionType);
    // assign all available to withdraw value when we initiated withdraw transaction
    dispatchTransactionState({
      type: CurrentTransactionActionTypes.Update,
      payload: {
        currentTransactionValue: yearnedYield,
      },
    });
  }, [transactionType]);

  // Initial balance validation
  useEffect(() => {
    if (currentBalance.eth.value < minEthRequired) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          errorMessage: `Insufficient funds for gas fees. Please deposit at least ${minEthRequired} ${currentBalance.eth.symbol} to proceed`,
          disabled: true,
        },
      });
    }

    if (yearnedYield < blendedPoolThreshold) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          disabled: true,
        },
      });
    }
  }, [accountState]);

  return (
    <div className="flex flex-col w-full mx-[50px]">
      <div className="pool-row-widget">
        <div className="pool-card-image">{image && <img src={image} alt="pool avatar" loading="eager" />}</div>
        <div className="pool-info">
          <h3>{name}</h3>
          <div className="pool-widget__details">
            <WidgetItem label="APY" value={apy} suffix="%" />
            <WidgetItem label="Emissions Averted" value={annualEmissionsAverted} suffix="kg" />
            <WidgetItem label="Hold Period" value={holdPeriod} suffix="days" />
          </div>
        </div>
      </div>
      {yearnedYield < blendedPoolThreshold && (
        <div className="reinvest-yield__custom-error">
          <img src={failIcon} alt="info icon" />
          <p>Not enough funds to meet minimum investment threshold of {numberWithCommas(blendedPoolThreshold)} USDC</p>
        </div>
      )}
    </div>
  );
};
