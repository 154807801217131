import { useContext, useReducer } from 'react';
import { ListDataHandlerInterface } from './interfaces';
import { ListActionTypes, ListStateContext } from '../../context/states/ListState';
import { DataHandlerActionTypes } from '../interfaces';
import { dataStateReducer } from '../reducer';
import { requestInitialState } from '../initialDataState';
import { PoolInterface, ProjectInterface } from '../../context/states/ListState/interfaces';
import { BlockchainDataHandler } from '../BlockchainDataHandler';
import { httpRequest } from '../../../services';
import { POOL_LIST_API, PoolStatusTypes, PROJECT_LIST_API, RequestTypes } from '../../../constants';
import { blendedPoolMock } from '../../../constants/mockData';
import { generateRandomNumber } from '../../../utils';

export const ListDataHandler = (): ListDataHandlerInterface => {
  const [requestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const [, setRequestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const { listState, dispatchListState } = useContext(ListStateContext);
  const { getPool, getAccountPool } = BlockchainDataHandler();
  /**
   * Returns Helios app pools with blockchain data.
   */

  const getPoolList = async () => {
    try {
      const response = await httpRequest({
        url: POOL_LIST_API.GET_POOLS,
        method: RequestTypes.Get,
        withoutToken: true,
      });
      const temp: PoolInterface[] = response?.data || [];

      // add apy to each item in temp array
      temp.forEach(pool => {
        // eslint-disable-next-line no-param-reassign
        pool.apy = pool.apy || generateRandomNumber();
      });

      const pools = [{ ...blendedPoolMock }, ...temp];

      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools },
      });
      return pools;
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
      return [];
    }
  };

  const getBlockchainAccountPoolList = async () => {
    try {
      let currentPools = listState.pools || [];
      if (getAccountPool) {
        const blockchainData = await Promise.all(
          currentPools.map((pool: PoolInterface) => getAccountPool(pool.id as string)),
        );
        currentPools = currentPools.map((pool: PoolInterface, index) => ({
          ...pool,
          blockchainAccountData: blockchainData[index],
        })) as PoolInterface[];
      }
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  const getBlockchainPoolList = async () => {
    try {
      let currentPools: PoolInterface[] = listState.pools || [];

      if (getPool) {
        const blockchainPools = currentPools.filter(pool => pool.status !== PoolStatusTypes.Draft);
        const blockchainData = await Promise.all(
          blockchainPools.map(pool => getPool(pool.id as string)),
        );
        currentPools = blockchainPools.map((pool: PoolInterface, index) => ({
          ...pool,
          blockchainData: blockchainData[index],
        }));
      }
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  // This function updates pools list with blockchain data, combining the data from getPool and getAccountPool
  const getBlockchainPool = async (poolId: string) => {
    try {
      const currentPools: PoolInterface[] = listState.pools || [];
      const currentPoolIndex: number = currentPools.findIndex(pool => pool.id === poolId);

      if (currentPoolIndex !== -1) {
        let currentPool = currentPools[currentPoolIndex];
        if (getPool) {
          const blockchainData = await getPool(currentPool.id as string);
          if (currentPool.status !== PoolStatusTypes.Draft) {
            currentPool = { ...currentPool, blockchainData };
          }
        }
        if (getAccountPool) {
          const blockchainAccountData = await getAccountPool(currentPool.id as string);
          if (currentPool.status !== PoolStatusTypes.Draft) {
            currentPool = { ...currentPool, blockchainAccountData };
          }
        }
        currentPools[currentPoolIndex] = currentPool;
      }

      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  /**
   * Returns projects.
   *
   * Will be called only in app for now (21.01.23)
   */

  const getProjectList = async () => {
    try {
      const response = await httpRequest({
        url: PROJECT_LIST_API.GET_PROJECTS,
        method: RequestTypes.Get,
        withoutToken: true,
      });

      const projects: ProjectInterface[] = response?.data || [];
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { projects },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };
  return {
    requestState,
    getPoolList,
    getBlockchainPoolList,
    getBlockchainAccountPoolList,
    getProjectList,
    getBlockchainPool,
  };
};
