import { WidgetConfig } from '@lifi/widget';

export enum PoolStatusTypes {
  Draft = 'Draft',
  Active = 'Active',
  Closed = 'Closed',
}

export enum ProjectStatusTypes {
  PreClosing = 'PreClosing',
  Active = 'Active',
  Exited = 'Exited',
  Dead = 'Dead',
}

export enum RegionTypes {
  All = 'All',
  None = 'None',
  Europe = 'Europe',
  Americas = 'Americas',
  Asia = 'Asia',
  Africa = 'Africa',
  Oceania = 'Oceania',
  Antarctic = 'Antarctic',
}

export const REGIONS = [
  RegionTypes.All,
  RegionTypes.Americas,
  RegionTypes.Asia,
  RegionTypes.Africa,
  RegionTypes.Europe,
];

export enum ConfigItemNameTypes {
  Platform = 'platform',
  Transactions = 'transactions',
  Account = 'account',
}

export enum TransactionStatusTypes {
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
  Default = 'Default',
}

export const DISCORD_LINK = 'https://discord.gg/P3Knb83URJ';
export enum SystemIDs {
  BlendedPool = 'blended_pool',
}

export const minEthRequired = 0.001;

export const widgetConfig: WidgetConfig = {
  variant: 'drawer',
  integrator: 'helios-dev',
};
