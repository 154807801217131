import { useContext, useState } from 'react';
import { AppModalActionTypes, ConfigStateContext } from '../../../store';
import arrowBackIcon from '../../../assets/images/arrow-back.svg';
import closeIcon from '../../../assets/images/close-icon.svg';
import { ActionButtons } from '../shared/ActionButtons';
import { useCurrentTransactionSteps } from './content/hooks/useTransactionSteps';
import { CurrentTransactionType } from '../../../constants';

export const TransactionModal = () => {
  const { appModal } = useContext(ConfigStateContext);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const transactionType = appModal?.transactionType;

  const steps = useCurrentTransactionSteps(transactionType || CurrentTransactionType.WithdrawInvestment);

  const stepActions = steps[currentStepIndex]?.stepActions || [];
  const stepTitle = steps[currentStepIndex]?.stepTitle;
  const stepSubtitle = steps[currentStepIndex]?.stepSubtitle;
  const stepDescription = steps[currentStepIndex]?.stepDescription;
  const systemMessage = appModal?.info?.systemMessage;
  const bonusAction = appModal?.info?.bonusAction;

  const hideTransactionModal = () => {
    dispatchAppModal({ type: AppModalActionTypes.Close });
  };
  const goToNextStep = () => {
    setCurrentStepIndex(prevIndex => Math.min(prevIndex + 1, steps.length - 1));
  };

  const goToPreviousStep = () => {
    setCurrentStepIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };
  return (
    <div className="transaction-modal">
      {/* Common Header */}
      <div className="transaction-modal__header">
        <div className="transaction-modal__header__left">
          {!!steps.length && currentStepIndex > 0 && (
            <button className="transaction-modal__header__btn" onClick={goToPreviousStep}>
              <img src={arrowBackIcon} alt="Back icon" />
            </button>
          )}
        </div>
        <div className="transaction-modal__header__right">
          <button onClick={hideTransactionModal} className="transaction-modal__header__btn">
            <img src={closeIcon} alt="Close icon" />
          </button>
        </div>
      </div>

      {/* Common Body */}
      <div className="transaction-modal__body relative">
        <div className="transaction-modal__header__title-block">
          {!!stepTitle && <h1 className="transaction-modal__title">{stepTitle}</h1>}
          {!!stepSubtitle && <h2 className="transaction-modal__subtitle">{stepSubtitle}</h2>}
        </div>
        {!!stepDescription && <p className="transaction-modal__description">{stepDescription}</p>}
        {steps.length > 0 && <div className="transaction-modal__content">{steps[currentStepIndex].component}</div>}
        {/* Is used to display error message of the native coin balance validation, if it fails in the InitiateBlendedPoolYieldInvest Component */}
        {/* Absolute positioning - to avoid annoying layout shift when the modal is opened */}
        {transactionType === CurrentTransactionType.InvestYield && (
          <span className="text-red-900 absolute bottom-[39%]">{appModal.errorMessage}</span>
        )}

        {stepActions.length > 0 && (
          <ActionButtons
            items={stepActions.map(action => ({
              label: action.label,
              callBack: action?.onClick ? action?.onClick : goToNextStep,
              /* Disable next step click if the modal has errors */
              disabled: action?.disabled,
              category: action?.category,
            }))}
          />
        )}
        {!!bonusAction && currentStepIndex === 0 && (
          <div className="transaction-modal__bonus-action" onClick={bonusAction.onClick}>
            {bonusAction.label}
          </div>
        )}

        {/* First step of withdraw yield screen should show bonus action instead of a system message */}
        {!!systemMessage && (
          <>
            {transactionType === CurrentTransactionType.WithdrawYield && currentStepIndex === 1 && (
              <p className="transaction-modal__system-message">{systemMessage}</p>
            )}
            {transactionType !== CurrentTransactionType.WithdrawYield && (
              <p className="transaction-modal__system-message">{systemMessage}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};
