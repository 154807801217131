import { useCallback, useContext } from 'react';
import { HelElement, HelFC } from '../../interfaces';
import { ButtonBase, PoolDetailBlock } from '../../components';
import { InvestmentCardProps, PoolDetailProps } from './interfaces';
import { CategoryTypes, CurrentTransactionType, PoolStatusTypes, SystemIDs } from '../../constants';
import {
  BlockchainPoolAccountInterface,
  BlockchainPoolInterface,
} from '../../store/context/states/ListState/interfaces';
import { AppModalActionTypes, ConfigStateContext } from '../../store';
import { lockupPeriodSM, withdrawInvestmentSM } from '../../constants/systemMessages';
import { PoolLink } from '../../components/shared/PoolLink';
import bpCover from '../../assets/images/bp-cover.jpg';

export const InvestmentCard: HelFC<InvestmentCardProps> = ({ className, pool }): HelElement => {
  const { dispatchAppModal } = useContext(ConfigStateContext);

  const {
    name,
    images,
    status,
    blockchainData = {} as BlockchainPoolInterface,
    blockchainAccountData = {} as BlockchainPoolAccountInterface,
    apy,
    id,
  } = pool;

  const { totalDeposited = 0, poolSize = 0, lockupPeriod = 0 } = blockchainData;
  const canAddMoreFunds = totalDeposited < poolSize;

  const { investedAmount = 0, accountPoolBalance = 0, yearnedYield = 0 } = blockchainAccountData;

  const poolDetailList: PoolDetailProps[] = [
    {
      label: 'Your Deposit',
      value: investedAmount,
      prefix: '$',
    },
    {
      label: 'Your Balance',
      value: accountPoolBalance,
      prefix: '$',
    },
    {
      label: 'APY',
      value: apy || 0,
      suffix: '%',
    },
    {
      label: 'Hold period',
      value: lockupPeriod || 0,
      suffix: lockupPeriod > 0 ? 'days' : 'day',
    },
  ];
  const accountPoolDetailList: PoolDetailProps[] = [
    {
      label: 'Invested',
      value: investedAmount,
      prefix: '$',
    },
    {
      label: 'Yield',
      value: yearnedYield,
      prefix: '$',
    },
  ];

  const openInvestModal = useCallback(() => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        title: 'Invest funds',
        transactionType: CurrentTransactionType.InvestFunds,
        data: pool,
        info: {
          systemMessage: lockupPeriodSM,
        },
      },
    });
  }, [dispatchAppModal, pool]);

  // TODO: Refactor this so it is not duplicated
  const openWithdrawInvestmentModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        data: pool,
        /* Transaction type is required to pick the right transaction steps */
        transactionType: CurrentTransactionType.WithdrawInvestment,
        info: {
          systemMessage: withdrawInvestmentSM,
        },
      },
    });
  };
  const openWithdrawYieldModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        data: pool,
        /* Transaction type is required to pick the right transaction steps */
        transactionType: CurrentTransactionType.WithdrawYield,
        info: {
          bonusAction: {
            label: 'Withdraw your investment from Helios?',
            onClick: () => {
              openWithdrawInvestmentModal();
            },
          },
          systemMessage:
            'Please note that funds are locked up for a period of 90 days before you are able to withdraw. Subsequent deposits will reset the timer based on a weighted average calculation between your initial and subsequent deposits.',
        },
      },
    });
  };
  const openWithdrawModal = () => {
    /* If we have yield to withdraw, steps should be for Withdraw Yield, if not - show Withdraw Investment steps. */
    /* Steps are defined inside TransactionModal component based on the result of useModalSteps custom hook */
    if (yearnedYield > 0) {
      openWithdrawYieldModal();
    } else {
      openWithdrawInvestmentModal();
    }
  };

  const openReinvestModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        title: 'Reinvest Yield',
        transactionType: CurrentTransactionType.InvestYield,
        data: pool,
        info: {
          systemMessage: lockupPeriodSM,
        },
      },
    });
  };

  return (
    <div className={`investment-card ${className || ''} relative`}>
      {status === PoolStatusTypes.Closed && (
        <span className="absolute top-5 right-5 bg-[#FF3C3C] rounded-full text-[11px] px-3 py-1 font-primary-semibold text-white-strong">
          {status?.toLowerCase()}
        </span>
      )}
      {images?.length ? (
        <img src={id === SystemIDs.BlendedPool ? bpCover : images[0].urls.original} alt="pool 1" />
      ) : (
        <div className="skeleton" />
      )}
      <h4>{name}</h4>
      {investedAmount && <div className="apy">{`APY ${apy}%`}</div>}
      {investedAmount ? (
        <div className="account-investment-info">
          <div className="account-balance">
            <span className="label">Balance</span>
            <div className="value">
              <span>${accountPoolBalance || 0}</span>
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                openWithdrawModal();
              }}
              className="withdraw"
            >
              Withdraw
            </div>
          </div>
          <div className="investment-details">
            {accountPoolDetailList.map(({ value, label }, index) => (
              // <div key={index}>
              <PoolDetailBlock key={index} value={value} label={label as string} prefix="$" />
              // </div>
            ))}
          </div>
        </div>
      ) : (
        <PoolLink id={pool.id as string}>
          <div className="pool-investment-info">
            <div className="investment-details">
              {poolDetailList.map(({ value, label, suffix, prefix }, index) => (
                <div key={index}>
                  <PoolDetailBlock value={value} label={label as string} suffix={suffix} prefix={prefix} />
                </div>
              ))}
            </div>
          </div>
        </PoolLink>
      )}
      <div
        className="action-buttons"
        onClickCapture={event => {
          // target is not a button, stop propagation  (the case where disabled button is clicked)
          if ((event.target as HTMLElement).tagName !== 'BUTTON') {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      >
        <ButtonBase
          label="Add Funds"
          onClick={openInvestModal}
          stopPropagation
          category={status === PoolStatusTypes.Closed ? CategoryTypes.Disabled : CategoryTypes.Filled}
          disabled={(!canAddMoreFunds && pool.id !== SystemIDs.BlendedPool) || status === PoolStatusTypes.Closed}
        />
        <ButtonBase
          label="Reinvest Yield"
          category={yearnedYield ? CategoryTypes.Outlined : CategoryTypes.Disabled}
          onClick={openReinvestModal}
          disabled={!yearnedYield}
          stopPropagation
        />
      </div>
    </div>
  );
};
