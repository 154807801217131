import { HelElement, HelFC } from '../../interfaces';
import { PoolDetailProps } from '../../modules/cards/interfaces';
import { numberWithCommas } from '../../utils';

export const PoolBalanceProgressBlock: HelFC<PoolDetailProps> = ({
  className,
  value = 0,
  currentValue = 0,
  suffix,
  prefix,
}): HelElement => {
  const currentValuePercent = ((currentValue as number) * 100) / (value as number);

  // Do not show for Blended Pool
  if (value === 0) return null;

  return (
    <div className={`pool-progress-block ${className || ''}`}>
      <div className="progress-info">
        <span className="current">{`${prefix || ''}${numberWithCommas(currentValue as number)} ${suffix || ''}`}</span>
        <span className="total">{`of ${prefix || ''}${numberWithCommas(value as number)} ${suffix || ''}`}</span>
      </div>
      <div className="max-value-progress">
        <div className="current-value-progress" style={{ width: `${currentValuePercent}%` }} />
      </div>
    </div>
  );
};
