import { useState } from 'react';
import moment from 'moment';
import { HelElement, HelFC } from '../../interfaces';
import logo from '../../assets/images/Logo.png';
import txArrowIcon from '../../assets/images/arrow-right-up.svg';
import { TransactionCardProps } from './interfaces';
import { useCopyToClipboard } from '../../services';
import copyIcon from '../../assets/images/copy-icon.svg';

export const TransactionCard: HelFC<TransactionCardProps> = (
  {
    item: {
      txHash,
      from,
      txDate,
      value = 0,
      className,
      method,
      symbol,
    },
  },
): HelElement => {
  const [copy] = useCopyToClipboard();
  const [copiedOpen, setCopiedOpen] = useState(false);

  const address = `${from?.slice(0, 4)}....${from?.slice(-4)}`;

  const handleCopyClick = async () => {
    await copy(from as string).then(result => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 2000);
      }
    });
  };

  return (
    <>
      {method === 'repay' && (
        <div className={`transaction-card ${className || ''}`}>
          <div className="from">
            <img className="logo" src={logo} alt="logo" />
            <span className="address">
              {address}
            </span>
            <img className="copy" role="presentation" onClick={handleCopyClick} src={copyIcon} alt="copy" />
            {copiedOpen && <div className="copy-popup">copied!</div>}
          </div>
          <div className="value">
            {`+${Math.round(value * 100) / 100
            } ${symbol || ''}`}
          </div>
          <div className="date">{moment(txDate).format('MMM D, yyyy')}</div>
          <div className="hash">
            <a href={`${process.env.REACT_APP_TRANSACTION_EXPLORER_LINK}/tx/${txHash}`} target="_blank" rel="noreferrer">
              <span>Tx</span>
              <img src={txArrowIcon} alt="tx-logo" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};
