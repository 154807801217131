import { AppModalStateInterface, AppModalStateReducerInterface } from './interfaces';
import { appModalInitialState } from './initialState';
import { AppModalActionTypes } from './actions';
import { ModalTypes } from '../../../../../constants';

export const appModalStateReducer: AppModalStateReducerInterface = (
  state = { ...appModalInitialState },
  action,
): AppModalStateInterface => {
  switch (action.type) {
    case AppModalActionTypes.ModalInfoSet:
      return {
        ...state,
        ...action.payload,
      };

    case AppModalActionTypes.MessageModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.MessageModal,
      };

    case AppModalActionTypes.DepositModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.DepositModal,
      };

    case AppModalActionTypes.InformModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.InformModal,
      };

    case AppModalActionTypes.InfoModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.InfoModal,
      };
    case AppModalActionTypes.TransactionModal:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
        modalType: ModalTypes.TransactionModal,
      };

    case AppModalActionTypes.Close:
      return {
        ...appModalInitialState,
      };

    default:
      return state;
  }
};
