import { useContext } from 'react';
import { ButtonBase } from '../../../components';
import { AppModalActionTypes, ConfigStateContext } from '../../../store';
import { HelElement, HelFC } from '../../../interfaces';
import { CategoryTypes, SizeTypes } from '../../../constants';
import { ActionButtonsProps } from './interfaces';

export const ActionButtons: HelFC<ActionButtonsProps> = (props): HelElement => {
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { className, items } = props;

  return (
    <div className={`action-buttons ${className}`}>
      {items.map(
        ({
          category = CategoryTypes.Outlined,
          disabled = false,
          label = 'Cancel',
          callBack = () => dispatchAppModal({ type: AppModalActionTypes.Close }),
        }) => (
          <ButtonBase
            category={disabled ? CategoryTypes.Disabled : category}
            label={label}
            onClick={callBack}
            size={SizeTypes.Large}
            disabled={disabled}
            key={label}
          />
        ),
      )}
    </div>
  );
};
