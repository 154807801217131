import { useCallback, useContext } from 'react';
import { isMetaMaskInstalled, switchNetwork } from '../../../utils';
// eslint-disable-next-line import/no-cycle
import { AppModalActionTypes, ConfigStateContext } from '../../../store';
import { useConnectWallet } from './useWallet';

// Define types for the hook's return value
interface UseEstablishConnectionResult {
  establishConnection: () => Promise<void>;
}

export function useWeb3Connection(): UseEstablishConnectionResult {
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { connectWallet } = useConnectWallet();

  const establishConnection = useCallback(async () => {
    if (!isMetaMaskInstalled()) {
      dispatchAppModal({
        type: AppModalActionTypes.MessageModal,
        payload: {
          title: 'MetaMask is not installed',
          description: 'Please install MetaMask to continue',
          action: (
            <p className="pt-3">
              <a
                href="https://metamask.io/download.html"
                target="_blank"
                rel="noreferrer"
                className="underline text-pink-light hover:text-pink-strong"
              >
                Install MetaMask
              </a>
            </p>
          ),
        },
      });
      return;
    }

    try {
      const id = await window.ethereum.request({
        method: 'eth_chainId',
      });

      if (id && Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID) {
        await switchNetwork(process.env.REACT_APP_CHAIN_ID as string);
      }
      await connectWallet();
    } catch (ex) {
      console.error(ex);
    }
  }, []);

  return { establishConnection };
}
