import twitter from '../../assets/social/twitter.svg';
import linkedin from '../../assets/social/linkedin.svg';

export const Footer = () => (
  <footer className="max-w-[70rem] w-full mb-16 mt-[97px] flex flex-col">
    <div className="flex flex-col-reverse sm:px-6 md:flex-row md:justify-center">
      <div className="flex flex-1 flex-col items-center gap-[46px] md:items-start">
        <div className="flex flex-col items-center md:items-start">
          <img src="/Logo.png" alt="logo" width={40} height={40} />
          <p className="mt-4 max-w-[148px] text-center font-primary-regular text-base md:text-start">
            Profit by saving the planet with Helios
          </p>
        </div>
      </div>
      <div className="flex flex-1 flex-col flex-wrap justify-between sm:flex-row">
        <div className="flex-1">
          <ul className="flex flex-col items-center gap-6 md:items-start list-none md:my-0 p-0">
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                About
              </a>
            </li>
            <li>
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Team
              </a>
            </li>
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Documentation
              </a>
            </li>
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Projects
              </a>
            </li>
          </ul>
        </div>

        <div className="flex-1">
          <ul className="mt-6 flex flex-col items-center gap-6 list-none md:mt-0 md:items-start p-0">
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Contacts
              </a>
            </li>
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Terms of Use
              </a>
            </li>
            <li className="hover:text-pink-strong">
              <a href="/" className="font-primary-light text-sm font-bold leading-[14px] tracking-[0.5px]">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>

        {/* Social media links - Twitter, LinkedIn */}
        <div className="my-6 sm:my-0">
          <ul className="flex justify-center gap-[21.5px] md:justify-start list-none p-0 m-0">
            <li>
              <a
                href="https://www.linkedin.com/company/heliosrefi/"
                target="_blank"
                className="font-primary-light text-sm font-bold tracking-[0.5px]"
                rel="noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" width={18} height={18} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/HeliosReFi"
                target="_blank"
                className="font-primary-light text-sm font-bold tracking-[0.5px]"
                rel="noreferrer"
              >
                <img src={twitter} alt="Twitter(X)" width={18} height={18} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="px-6 font-primary-light text-xs mb-3 mt-5">
      <p>
        Hyperion BVI LTD (&quot;Helios&quot;) is a technology services provider. Use of the Helios Protocol involves
        risks, including but not limited to the potential loss of digital assets. The Helios Protocol is provided on an
        “as is” and “as available” basis, at your own risk. We explicitly disclaim any representation or warranties of
        any kind relating to the Protocol, and no developer or entity will be liable for claims or damages of any kind
        associated with use or inability to use the Protocol.
      </p>
      <br />
      <p>
        Past performance is no guarantee of future results. Any historical returns, expected returns, or probability
        projections may not reflect actual future performance. All investments involve risk and may result in partial or
        total loss. Neither Helios nor any of its affiliates provide tax advice and do not represent in any manner that
        the outcome described herein will result in any particular tax consequence. Prospective investors should confer
        with their personal tax advisors regarding the tax consequences of an investment based on their particular
        circumstances. Neither Helios nor any of its affiliates assume responsibility for the tax consequences of any
        investment for any investor. Additionally, neither Helios nor any of its affiliates provide investment advice in
        any manner. We can only provide information about our offerings and how to use our platform. Any investment
        decisions based on the information we provide are purely that of the investor.
      </p>
      <br />
      <p>
        For questions or assistance, please
        {/* eslint-disable-next-line no-irregular-whitespace */}
        reach out:{' '}
        <a href="mailto:team@helios.eco" className="hover:text-pink-strong hover:underline">
          team@helios.eco
        </a>
      </p>
    </div>
    <div className="px-6">
      <span className="font-primary-light text-xs font-bold leading-[19.2px] tracking-[0.5px]">
        {/* eslint-disable-next-line no-irregular-whitespace */}© {new Date().getFullYear()} Hyperion. All Rights
        Reserved.
      </span>
    </div>
  </footer>
);
