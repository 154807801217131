import { createContext, useMemo, useReducer } from 'react';
import { TransactionActionType, TransactionStateContextInterface, TransactionStateInterface } from './interfaces';
import { currentTransactionInitialState } from './initialState';
import { transactionStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const CurrentTransactionStateContext = createContext<TransactionStateContextInterface>(
  {} as TransactionStateContextInterface,
);

export const CurrentTransactionStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    transactionState,
    dispatchTransactionState,
  ] = useReducer<
  UseReducerType<TransactionStateInterface, TransactionActionType>
  >(transactionStateReducer, currentTransactionInitialState);

  const value = useMemo(() => ({
    transactionState,
    dispatchTransactionState,
  }), [transactionState]);

  return (
    <CurrentTransactionStateContext.Provider
      value={value}
    >
      {children}
    </CurrentTransactionStateContext.Provider>
  );
};
