export const numberWithCommas = (value: number): string => {
  // Check if the number is greater than 1
  if (value >= 0) {
    // Fix to two decimals if there are any decimals greater than 0
    const fixedValue = value % 1 > 0 ? value.toFixed(2) : value.toString();
    // Format the number with commas
    return fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value.toFixed(1).toString();
};

export const formatLargeNumber = (value: number): string => {
  if (value < 1000) {
    // For numbers less than 1000, return as is with no decimal places
    return value.toString();
  }
  if (value < 1000000) {
    // For thousands, divide by 1,000 and add 'k'
    return `${(value / 1000).toFixed(1)}k`;
  }
  if (value < 1000000000) {
    // For millions, divide by 1,000,000 and add 'M'
    return `${(value / 1000000).toFixed(2)}M`;
  }
  if (value < 1000000000000) {
    // For billions, divide by 1,000,000,000 and add 'B'
    return `${(value / 1000000000).toFixed(2)}B`;
  }
  // For trillions and above, divide by 1,000,000,000,000 and add 'T'
  return `${(value / 1000000000000).toFixed(2)}T`;
};

export const getErrorMessage = (value = 0, symbol?: string) =>
  value ? `You can't claim more than ${numberWithCommas(value)} ${symbol}.` : 'available balance is zero';

export function generateRandomNumber(): number {
  return Math.floor(Math.random() * 8) + 7;
}
