import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { HelElement, HelFC } from '../../../../../interfaces';
import { PoolInterface } from '../../../../../store/context/states/ListState/interfaces';
import { useCopyToClipboard } from '../../../../../services';
import copyIcon from '../../../../../assets/images/copy-icon.svg';
import ethLink from '../../../../../assets/images/eth-link.svg';

export const InvestRecap: HelFC<{ pool: PoolInterface | undefined }> = ({ pool }): HelElement => {
  const [copy] = useCopyToClipboard();
  const [copiedOpen, setCopiedOpen] = useState(false);
  const { active } = useWeb3React();

  let address = '';
  if (active) {
    address = pool?.blockchainData?.poolAddress ? `${pool?.blockchainData?.poolAddress.slice(0, 4)}********${pool?.blockchainData?.poolAddress.slice(-4)}` : '';
  }

  const handleCopyClick = async () => {
    await copy(pool?.blockchainData?.poolAddress as string).then(result => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 1000);
      }
    });
  };

  return (
    <>
      <div className="contract">
        <div className="pool-name">
          {pool?.images?.length && <img src={pool?.images[0].urls.small || ''} alt="pool avatar" loading="eager" />}
          <h4>{pool?.name}</h4>
        </div>
        <div className="pool-actions">
          <span className="address">{address}</span>
          <button onClick={handleCopyClick}>
            <img src={copyIcon} alt="copy" />
          </button>
          {copiedOpen && <div className="copy-popup">copied!</div>}
          <a
            href={`${process.env.REACT_APP_TRANSACTION_EXPLORER_LINK}/address/${pool?.blockchainData?.poolAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={ethLink} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};
