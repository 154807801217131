import { useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { LiFiWidget } from '@lifi/widget';
import { Header } from '../Header';
import { HelElement, HelFC } from '../../interfaces';
import { AccountActionTypes, AccountStateContext, ListStateContext, TransactionStateContext } from '../../store';
import { ListDataHandler } from '../../store/DataHandlers/ListDataHandler';
import headerGradient from '../../assets/images/gradients/Header.png';
import { AccountDataHandler } from '../../store/DataHandlers/AccountDataHandler';
import { BlockchainDataHandler } from '../../store/DataHandlers/BlockchainDataHandler';
import { widgetConfig } from '../../constants';
import { Footer } from '../Footer';

export const AppLayout: HelFC = ({ children, className }): HelElement => {
  const { chainId = process.env.REACT_APP_CHAIN_ID!, account } = useWeb3React();
  const { getBlockchainAccountPoolList } = ListDataHandler();
  const { checkAddressIsIdentified } = AccountDataHandler();
  const { getAccountBalance } = BlockchainDataHandler();
  const { dispatchAccountState } = useContext(AccountStateContext);
  const {
    listState: { pools = [] },
  } = useContext(ListStateContext);
  const {
    transactionState: { transactions },
  } = useContext(TransactionStateContext);

  useEffect(() => {
    if (chainId.toString() === process.env.REACT_APP_CHAIN_ID!) {
      getBlockchainAccountPoolList(pools).catch(error => {
        console.log('Data getting Error:', error);
      });
    }
  }, [account, chainId]);

  useEffect(() => {
    if (account) {
      checkAddressIsIdentified(account).catch(error => {
        console.log('Identification check Error:', error);
      });
      getAccountBalance()
        .then(result => {
          dispatchAccountState({
            type: AccountActionTypes.SetAccount,
            payload: {
              balance: result,
            },
          });
        })
        .catch(error => {
          console.log('Account balance getting Error:', error);
        });
    }
  }, [account, transactions]);

  return (
    <div className={`app-layout ${!account ? 'not-connected' : ''} ${className}`}>
      <img className="gradient" src={headerGradient} alt="" />
      <Header />
      {/* Position widget label */}
      <LiFiWidget integrator="helios-dev" config={widgetConfig} />
      <div className="app-content-container">{children}</div>
      <Footer />
    </div>
  );
};
