import { PoolInterface } from '../store/context/states/ListState/interfaces';
import { PoolStatusTypes, SystemIDs } from './global';

export const blendedPoolMock: PoolInterface = {
  id: SystemIDs.BlendedPool,
  name: 'Blended Pool',
  apy: 8,
  status: PoolStatusTypes.Active,
  description:
    '<p>The Blended Pool is a managed, diversified portfolio consisting of ownership stakes in each of Helios’ ' +
    'regional pools. Each time a new pool is added, the Blended Pool will actively invest ' +
    'a portion of its funds into this new pool. By investing in the Blended Pool, ' +
    'users earn strong, stable yields backed by clean energy sales around the world. ' +
    'By virtue of diversified risk and active management, the Blended Pool is the safest and strongest pool on Helios, ' +
    'which we recommend to all of our users.</p>' +
    '<p>HLSp is the Helios Blended Pool’s liquidity token. Each HLSp is equivalent to ' +
    '$1 deposited into the Blended Pool. Unlike other Pools, the Blended Pool’s LP token can ' +
    'be exchanged on secondary markets like <b><a href="https://aerodrome.finance/tokenlist?query=HLSp" target="_blank" class="!underline text-pink-strong">Aerodrome</a></b>, dramatically increasing the liquidity ' +
    'of the token and effectively reducing the HLSp lockup period to 0. Users can both buy/sell HLSp on platforms ' +
    'like Aerodrome or deposit equal amounts of HLSp and USDC to generate even higher yields by providing ' +
    'liquidity to the trading pair.</p>' +
    '<b>TLDR; The Helios Blended Pool is the world’s first perfectly liquid, direct solar investment!</b>',
  descriptionTitle: 'Invest in a globally diversified pool of solar projects',
  overview: 'Globally diversified, highly liquid pool of active solar projects.',
  annualEmissionsAverted: 5000000,
  images: [
    {
      id: SystemIDs.BlendedPool,
      path: 'path',
      nullable: true,
      isPrimary: true,
      urls: {
        original: 'bp-cover.jpg',
        small: 'bp-cover.jpg',
      },
    },
  ],
};
