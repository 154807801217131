/* eslint-disable no-console */
import { ethers } from 'ethers';

// Function to convert BigNumber to decimal
const bigNumberToDecimal = (value: ethers.BigNumber, decimals: number): number =>
  parseFloat(ethers.utils.formatUnits(value, decimals));

// Function to convert decimal to BigNumber
const decimalToBigNumber = (value: number, decimals: number): ethers.BigNumber =>
  ethers.utils.parseUnits(value.toString(), decimals);

// Updated transformNumber function
export const transformNumber = (value: ethers.BigNumber | number, decimals: number, reverse: boolean) => {
  if (value instanceof ethers.BigNumber) {
    if (reverse) {
      return bigNumberToDecimal(value, decimals);
    }
    return value.toNumber();
  }
  if (reverse) {
    return value / 10 ** decimals;
  }
  return decimalToBigNumber(value, decimals).toNumber();
};

declare global {
  interface Window {
    ethereum: any;
  }
}

export const switchNetwork = async (id: string): Promise<void> => {
  const networkUrl = process.env.REACT_APP_CHAIN_NETWORK_URL;
  const explorerUrl = process.env.REACT_APP_TRANSACTION_EXPLORER_LINK;

  console.log(networkUrl, explorerUrl);

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${parseInt(id, 10).toString(16)}` }],
    });
  } catch (switchError: any) {
    if (switchError.code === 4902 || switchError.code === -32602) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${parseInt(id, 10).toString(16)}`,
              chainName: 'Base',
              nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
              rpcUrls: [networkUrl],
              blockExplorerUrls: [explorerUrl],
              iconUrls: ['future'],
            },
          ],
        });
      } catch (addError) {
        console.log('Error');
      }
    } else {
      console.log(switchError, "You can't switch your network right now");
    }
  }
};

export const isMetaMaskInstalled = () => !!(window && window.ethereum !== undefined && window.ethereum.isMetaMask);
