import { HelElement, HelFC } from '../../interfaces';
import { PoolCard } from '../cards';
import { PoolCardBoxProps } from './interfaces';
import { PoolLink } from '../../components/shared/PoolLink';
import { PoolDetailProps } from '../cards/interfaces';
import { SystemIDs } from '../../constants';

export const PoolCardBox: HelFC<PoolCardBoxProps> = ({ pools }): HelElement => (
  <div className="pool-cards-box">
    {pools.length ? (
      pools.map(({ name, images, overview, id, apy, annualEmissionsAverted, blockchainData = {} }, index) => {
        const { poolSize, totalDeposited } = blockchainData;

        const poolDetailList: PoolDetailProps[] = [
          {
            label: 'Pool size:',
            value: poolSize || 0,
            currentValue: totalDeposited || 0,
            prefix: '$',
          },
          {
            label: 'APY',
            value: apy || 0,
            suffix: '%',
          },
          {
            label: 'Annual Emissions Averted',
            value: (annualEmissionsAverted as number) || 0,
            suffix: 'kg',
          },
        ];
        const isFeatured = id === SystemIDs.BlendedPool;

        return (
          <div className={`card ${isFeatured ? 'featured-card' : ''}`} key={`pool-card-${index}`}>
            <PoolLink id={id as string}>
              <PoolCard
                title={name}
                image={images?.length ? images[0].urls.original : ''}
                description={overview}
                details={poolDetailList}
                id={id}
              />
            </PoolLink>
          </div>
        );
      })
    ) : (
      <div className="no-pool">There are no pools</div>
    )}
  </div>
);
