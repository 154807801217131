import { useContext } from 'react';

import {
  AppModalActionTypes,
  ConfigStateContext,
  CurrentTransactionStateContext,
  ListStateContext,
  useCloseAppModal,
} from '../../../../../store';
import { InitiateWithdraw } from '../Withdraw/InitiateWithdraw';
import {
  CategoryTypes,
  CurrentTransactionType,
  ModalInfoTypes,
  SystemIDs,
  WithdrawalTypes,
} from '../../../../../constants';
import { ConfirmWithdraw } from '../Withdraw/ConfirmWithdraw';
import { TransactionModalStep } from '../../../../../store/context/states/ConfigState/AppModalState/interfaces';
import { blendedPoolMock } from '../../../../../constants/mockData';
import { BlockchainPoolInterface } from '../../../../../store/context/states/ListState/interfaces';
import { InvestRecap } from '../Invest/InvestRecap';
import {
  useBlockchainInvest,
  useBlockchainWithdraw,
} from '../../../../../store/DataHandlers/hooks/useBlockchainTransactions';
import { InitiateBlendedPoolYieldInvest } from '../Invest/InitiateBlendedPoolYieldInvest';
import { lockupPeriodSM } from '../../../../../constants/systemMessages';
import { InitiateFundsInvest } from '../Invest/InitiateFundsInvest';
import { numberWithCommas } from '../../../../../utils';
import { WithdrawLocked } from '../Withdraw/WithdrawLocked';

export const useCurrentTransactionSteps = (transactionType: CurrentTransactionType) => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);

  const { data } = appModal;
  const { totalDeposited = 0, poolSize = 0 } = data?.blockchainData as BlockchainPoolInterface;
  // If it is Blended Pool, we need to set the maxDepositAmount to 100 (it doesn't have a pool size, just 100 each transaction)
  const maxDepositAmount = poolSize !== 0 ? poolSize - totalDeposited : 100;
  const availableToWithdraw = data?.blockchainAccountData?.availableToWithdraw || 0;
  const closeModal = useCloseAppModal();
  const {
    transactionState: { currentTransactionValue },
  } = useContext(CurrentTransactionStateContext);
  const listState = useContext(ListStateContext);
  const blendedPoolData = listState.listState.pools?.[0];

  const { handleInvest } = useBlockchainInvest({
    value: currentTransactionValue,
    // Here we have to pick the right pool to invest in, if it is InvestYield we need to use the blendedPoolData
    // If we invest funds directly into BP pool, appModal.data is equal to blendedPoolData
    pool: transactionType === CurrentTransactionType.InvestYield ? blendedPoolData : appModal.data,
  });

  const { handleWithdraw } = useBlockchainWithdraw({
    // Initial value here is 0, it will be updated in the InitiateWithdraw component
    value: currentTransactionValue,
    // Withdraw yield can be initiated via InvestYield transaction type,
    // So we need to check if the transaction type is WithdrawInvestment, otherwise it is WithdrawYield no matter what
    // Later on in the InitiateWithdraw we check the transaction type and set the currentWithdrawalType accordingly

    // Basically we conduct operation on one pool only, we just need to check if it is WithdrawInvestment or WithdrawYield
    type:
      transactionType === CurrentTransactionType.WithdrawInvestment
        ? WithdrawalTypes.Investment
        : WithdrawalTypes.Yield,
    pool: appModal.data,
  });

  const isReinvestAvailable = (blendedPoolMock?.blockchainData?.minInvestmentSize || 0) <= currentTransactionValue;
  let steps: TransactionModalStep[] = [];
  const symbol = data?.blockchainData?.symbol;

  switch (transactionType) {
    case CurrentTransactionType.WithdrawInvestment:
      if (availableToWithdraw > 0) {
        steps = [
          {
            key: 'Withdraw',
            stepTitle: 'Withdraw Investment',
            stepSubtitle: `Your total investment is ${numberWithCommas(
              data?.blockchainAccountData?.investedAmount || 0,
            )}`,
            stepDescription:
              'Withdrawing your Principal investment means you will no longer earn yield or avert CO2 emissions. Are you sure you want to abandon the fight against climate change?',
            component: <InitiateWithdraw />,
            stepActions: [
              {
                label: 'Cancel',
                onClick: () => {
                  closeModal();
                },
              },
              {
                label: 'Withdraw',
                category: CategoryTypes.Filled,
                disabled: appModal.disabled,
              },
            ],
          },
          {
            key: 'Confirm',
            stepTitle: 'Confirm Withdraw Investment',
            component: <ConfirmWithdraw />,
            stepActions: [
              {
                label: 'Cancel',
                onClick: () => {
                  closeModal();
                },
              },
              {
                label: 'Confirm',
                onClick: handleWithdraw,
                category: CategoryTypes.Filled,
                disabled: appModal.disabled,
              },
            ],
          },
        ];
      } else {
        steps = [
          {
            key: 'Withdraw',
            stepTitle: '',
            component: <WithdrawLocked />,
          },
        ];
      }

      break;
    case CurrentTransactionType.WithdrawYield:
      steps = [
        {
          key: 'Withdraw Yield',
          stepTitle: 'Withdraw Yield',
          stepSubtitle: `Your total yield is ${numberWithCommas(data?.blockchainAccountData?.yearnedYield || 0)}`,
          stepDescription:
            'Please note that funds are locked up for a period of 90 days before you are able to withdraw. Subsequent deposits will reset the timer.',
          component: <InitiateWithdraw />,
          stepActions: [
            {
              label: 'Reinvest Yield',
              onClick: () => {
                dispatchAppModal({
                  type: AppModalActionTypes.TransactionModal,
                  payload: {
                    title: 'Invest Yield',
                    transactionType: CurrentTransactionType.InvestYield,
                    data: appModal.data,
                    info: {
                      systemMessage: lockupPeriodSM,
                    },
                  },
                });
              },
              disabled: !isReinvestAvailable,
            },
            {
              label: 'Withdraw Yield',
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
        {
          key: 'Confirm',
          stepTitle: 'Confirm Withdraw Yield',
          component: <ConfirmWithdraw />,
          stepActions: [
            {
              label: 'Cancel',
              onClick: () => {
                closeModal();
              },
            },
            {
              label: 'Confirm',
              onClick: handleWithdraw,
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
      ];
      break;
    case CurrentTransactionType.InvestYield:
      steps = [
        {
          key: 'Initiate Invest',
          stepTitle: 'Invest Yield',
          stepSubtitle: `Total yield: ${numberWithCommas(data?.blockchainAccountData?.yearnedYield || 0)} ${symbol}`,
          component: <InitiateBlendedPoolYieldInvest />,
          stepActions: [
            {
              label: 'Cancel',
              onClick: () => {
                closeModal();
              },
            },
            {
              label: 'Next',
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
        {
          key: 'Invest Yield Recap',
          stepTitle: 'Invest Yield Recap',
          component: <InvestRecap pool={blendedPoolData} />,
          stepActions: [
            {
              label: 'Cancel',
              onClick: () => {
                closeModal();
              },
            },
            {
              label: 'Invest Yield',
              onClick: () => {
                handleWithdraw().then(withdrawResponse => {
                  if (!withdrawResponse?.status) {
                    dispatchAppModal({
                      type: AppModalActionTypes.InfoModal,
                      payload: {
                        cancelButtonText: 'Return to dashboard',
                        cancelCallBack: () => {
                          withdrawResponse?.navigateFunction?.();
                          dispatchAppModal({ type: AppModalActionTypes.Close });
                        },
                        info: {
                          type: ModalInfoTypes.Fail,
                          value: withdrawResponse?.value || 0,
                          description: withdrawResponse?.errorMessage || 'An error occurred while withdrawing',
                          label: 'Withdrawal Failed',
                        },
                      },
                    });
                  } else {
                    handleInvest().then(investData => {
                      if (!investData?.status) {
                        dispatchAppModal({
                          type: AppModalActionTypes.InfoModal,
                          payload: {
                            cancelButtonText: 'Return to dashboard',
                            cancelCallBack: () => {
                              investData?.navigateFunction?.();
                              dispatchAppModal({ type: AppModalActionTypes.Close });
                            },
                            info: {
                              type: ModalInfoTypes.Fail,
                              value: investData?.value || 0,
                              description: investData?.errorMessage || 'An error occurred while investing',
                              label: 'Investment Failed',
                            },
                          },
                        });
                      }
                      closeModal();
                    });
                  }
                });
              },
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
      ];
      break;
    case CurrentTransactionType.InvestFunds:
      steps = [
        {
          key: 'Initiate Invest',
          stepTitle: 'Invest Funds',
          stepSubtitle: (
            <div className="strong-grey-text">
              Max Available:{' '}
              <span className="strong-grey-value">
                {appModal.data && appModal.data.id === SystemIDs.BlendedPool
                  ? 'Unlimited'
                  : `${numberWithCommas(maxDepositAmount)} ${symbol}`}
              </span>
            </div>
          ),
          component: <InitiateFundsInvest />,
          stepActions: [
            {
              label: 'Cancel',
              onClick: () => {
                closeModal();
              },
            },
            {
              label: 'Next',
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
        {
          key: 'Invest Recap',
          stepTitle: 'Invest Recap',
          stepSubtitle: `You are investing ${numberWithCommas(currentTransactionValue)} ${symbol} into`,
          component: <InvestRecap pool={appModal.data} />,
          stepActions: [
            {
              label: 'Cancel',
              onClick: () => {
                closeModal();
              },
            },
            {
              label: 'Invest',
              onClick: handleInvest,
              category: CategoryTypes.Filled,
              disabled: appModal.disabled,
            },
          ],
        },
      ];
      break;
    default:
      break;
  }

  return steps;
};
