import { HelElement, HelFC } from '../../interfaces';
import { PoolDetailProps } from '../../modules/cards/interfaces';
import { numberWithCommas } from '../../utils/formatting';

export const ProgressBlock: HelFC<PoolDetailProps> = ({
  label,
  className,
  value,
  currentValue = 0,
  prefix,
  suffix,
}): HelElement => {
  const currentValuePercent = ((currentValue as number) * 100) / (value as number);

  return (
    <div className={`progress-block ${className || ''}`}>
      <div className="progress-info">
        <span className="label">{label}</span>
        <div className="value-content">
          {prefix && <span className="symbol">{prefix}</span>}
          {value > 0 ? (
            <>
              <span className="value">${numberWithCommas(value)}</span>
              {suffix && <span className="symbol">{suffix}</span>}
            </>
          ) : (
            // Blended pool case
            <span>Infinite</span>
          )}
        </div>
      </div>
      <div className="max-value-progress">
        <div className="current-value-progress" style={{ width: `${currentValuePercent}%` }} />
      </div>
    </div>
  );
};
