import { useWeb3React } from '@web3-react/core';
import { switchNetwork } from '../../../utils';
import { injected } from '../connectors';

export const useConnectWallet = () => {
  const {
    activate, account, active, deactivate, chainId,
  } = useWeb3React();

  const connectWallet = async () => {
    try {
      const id = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (id && (Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID)) {
        await switchNetwork(process.env.REACT_APP_CHAIN_ID as string);
      } else {
        try {
          if (id && Number(id)?.toString() === process.env.REACT_APP_CHAIN_ID!) {
            localStorage.setItem('isWalletConnected', 'true');
          }
        } catch (e) {
          console.log(e);
        }
      }
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  };

  return {
    connectWallet,
    account,
    active,
    deactivate,
    chainId,
  };
};
