import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelElement, HelFC } from '../../interfaces';
import successIcon from '../../assets/images/success-icon.svg';
import failIcon from '../../assets/images/fail-icon.svg';
import { ModalBase } from './ModalBase';
import { CategoryTypes, ModalInfoTypes } from '../../constants';
import { InfoModalProps } from './interfaces';
import { ActionButtons } from './shared/ActionButtons';
import { CurrentTransactionStateContext, useCloseAppModal } from '../../store';

export const InfoModal: HelFC<InfoModalProps> = ({ className, type, label, description }): HelElement => {
  const infoIcon = type === ModalInfoTypes.Success ? successIcon : failIcon;
  const closeModal = useCloseAppModal();

  const navigate = useNavigate();
  const { transactionState } = useContext(CurrentTransactionStateContext);
  const { currentTransactionValue } = transactionState;

  return (
    <ModalBase className="modal-medium transaction-result-modal">
      <div>
        <img src={infoIcon} alt="info icon" />
        <div className={`status ${type} ${className}`}>
          <div className="label">{label}</div>
          <div className="value">
            <div>{currentTransactionValue}</div>
            <div className="symbol">{process.env.REACT_APP_STABLECOIN_SYMBOL}</div>
          </div>
        </div>
        <div className="description">{description}</div>
      </div>
      <ActionButtons
        items={[
          {
            category: CategoryTypes.Outlined,
            label: 'Return to Dashboard',
            callBack: () => {
              navigate('/pools');
              closeModal();
            },
          },
        ]}
      />
    </ModalBase>
  );
};
