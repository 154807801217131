import { useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  AccountStateContext,
  AppModalActionTypes,
  ConfigStateContext,
  CurrentTransactionActionTypes,
  CurrentTransactionStateContext,
} from '../../../../../store';
import { LockedValue } from '../../../../../components';
import { CurrentTransactionType, minEthRequired } from '../../../../../constants';
import { getErrorMessage, numberWithCommas } from '../../../../../utils';

export const InitiateWithdraw = () => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const {
    transactionState: { currentTransactionValue },
    dispatchTransactionState,
  } = useContext(CurrentTransactionStateContext);
  const { data, transactionType } = appModal;
  const symbol = data?.blockchainData?.symbol;
  const yieldEarned = data?.blockchainAccountData?.yearnedYield || 0;
  const availableToWithdraw = data?.blockchainAccountData?.availableToWithdraw || 0;
  const { accountState } = useContext(AccountStateContext);
  const { balance } = accountState;
  const currentBalance = balance || {
    stableCoin: {
      value: 0,
      symbol: '',
    },
    eth: {
      value: 0,
      symbol: '',
    },
  };

  // State update happens when user changes the transaction type
  useEffect(() => {
    console.log(transactionType);
    // assign all available to withdraw value when we initiated withdraw transaction
    dispatchTransactionState({
      type: CurrentTransactionActionTypes.Update,
      payload: {
        currentTransactionValue:
          transactionType === CurrentTransactionType.WithdrawInvestment ? availableToWithdraw : yieldEarned,
      },
    });
  }, [transactionType]);

  useEffect(() => {
    if (currentBalance.eth.value < minEthRequired) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          errorMessage: `Insufficient funds for gas fees. You have ${numberWithCommas(currentBalance.eth.value)} ${
            currentBalance.eth.symbol
          } in your account`,
          disabled: true,
        },
      });
    }
  }, [accountState]);

  // State update happens when user types in the input field
  const validate = (value = 0) => {
    const isAmountInTheRange = value <= availableToWithdraw;
    const isEnoughEth = currentBalance.eth.value > minEthRequired;

    if (!isEnoughEth) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          errorMessage: `Insufficient funds for gas fees. Please deposit at least ${minEthRequired} ${currentBalance.eth.symbol} to proceed`,
          disabled: !isEnoughEth,
        },
      });
      return;
    }

    if (value) {
      if (!isAmountInTheRange) {
        dispatchAppModal({
          type: AppModalActionTypes.ModalInfoSet,
          payload: {
            error: !isAmountInTheRange,
            errorMessage: getErrorMessage(availableToWithdraw, symbol),
            disabled: !isAmountInTheRange,
          },
        });
        return;
      }
    } else {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: value < 0,
          errorMessage: !(value >= 0) ? 'Enter amount of withdrawal' : '',
          disabled: !value,
        },
      });
      return;
    }

    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        error: false,
        errorMessage: '',
        disabled: false,
      },
    });
  };

  return (
    <div className="transaction-container">
      <div className="modal-input">
        {appModal.errorMessage && <p className="error-message">{appModal.errorMessage}</p>}
        {transactionType === CurrentTransactionType.WithdrawInvestment && (
          <NumericFormat
            className={`${appModal.error ? 'error' : ''} input`}
            value={currentTransactionValue}
            onValueChange={({ floatValue = 0 }) => {
              validate(floatValue);
              dispatchTransactionState({
                type: CurrentTransactionActionTypes.Update,
                payload: {
                  currentTransactionValue: floatValue || 0,
                },
              });
            }}
            placeholder="Withdraw amount"
            suffix={` ${symbol}`}
            thousandSeparator
          />
        )}
        {transactionType === CurrentTransactionType.WithdrawYield && (
          <div className="pseudo-input input">
            <LockedValue
              value={`${numberWithCommas(yieldEarned)} ${symbol}`}
              iconClasses="pseudo-input__icon"
              valueClasses="pseudo-input__value"
            />
          </div>
        )}
      </div>
    </div>
  );
};
