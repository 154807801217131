import { useContext, useEffect, useState } from 'react';
import { ConfigStateContext, CurrentTransactionStateContext } from '../../../../../store';

export const ConfirmWithdraw = () => {
  const { transactionState: { currentTransactionValue } } = useContext(CurrentTransactionStateContext);
  const { appModal } = useContext(ConfigStateContext);
  const [currentValue, setCurrentValue] = useState(currentTransactionValue);
  const { data } = appModal;
  const symbol = data?.blockchainData?.symbol;
  const message = 'You are withdrawing';

  useEffect(() => {
    setCurrentValue(currentTransactionValue);
  }, [appModal]);

  return (
    <div className="confirmation-message">
      {message}
      {' '}
      {currentValue}
      {' '}
      {symbol}
    </div>
  );
};
