import { BadgeProps } from '../interfaces';
import { HelElement, HelFC } from '../../interfaces';
import { IconDown } from '../icons';

export const ArrowBadge: HelFC<BadgeProps> = ({ label, className }): HelElement => (
  <div className={`badge arrow ${className}`}>
    <IconDown />
    <span>
      <h6>{label}</h6>
    </span>
  </div>
);
