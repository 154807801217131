export enum BlockchainErrorMessages {
  POOL_NOT_FOUND = 'POOL_NOT_FOUND',
  ACTION_REJECTED = 'ACTION_REJECTED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  INVALID_ARGUMENTS = 'INVALID_ARGUMENTS',
  GAS_LIMIT_EXCEEDED = 'GAS_LIMIT_EXCEEDED',
  INSUFFICIENT_GAS_PRICE = 'INSUFFICIENT_GAS_PRICE',
  UNPREDICTABLE_GAS_LIMIT = 'UNPREDICTABLE_GAS_LIMIT',
  'PL:ZERO_AMOUNT' = 'PL:ZERO_AMOUNT',
  'P:ZERO_YIELD' = 'P:ZERO_YIELD',
  'BP:INSUFFICIENT_FUNDS' = 'BP:INSUFFICIENT_FUNDS',
  'BP:TOKENS_LOCKED' = 'BP:TOKENS_LOCKED',
  'P:BAD_STATE' = 'P:BAD_STATE',
}

export const getBlockchainErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case BlockchainErrorMessages.POOL_NOT_FOUND:
      return 'Pool not found';
    case BlockchainErrorMessages.ACTION_REJECTED:
      return 'You rejected the transaction';
    case BlockchainErrorMessages.NETWORK_ERROR:
      return 'Network error occurred';
    case BlockchainErrorMessages.INVALID_ARGUMENTS:
      return 'Invalid arguments provided';
    case BlockchainErrorMessages.GAS_LIMIT_EXCEEDED:
      return 'Gas limit exceeded';
    case BlockchainErrorMessages.INSUFFICIENT_GAS_PRICE:
      return 'Insufficient gas price';
    case BlockchainErrorMessages.UNPREDICTABLE_GAS_LIMIT:
      return 'Unpredictable gas limit. Please contact support.';
    case BlockchainErrorMessages['PL:ZERO_AMOUNT']:
      return 'You cannot invest 0 amount';
    case BlockchainErrorMessages['P:ZERO_YIELD']:
      return 'You cannot withdraw 0 yield';
    case BlockchainErrorMessages['BP:INSUFFICIENT_FUNDS']:
      return 'Insufficient funds';
    case BlockchainErrorMessages['BP:TOKENS_LOCKED']:
      return 'Tokens are locked. Please try again later.';
    case BlockchainErrorMessages['P:BAD_STATE']:
      return 'The pool is not longer available for investment. Please try with a different pool.';
    default:
      return 'Something went wrong. Please try again later.';
  }
};
