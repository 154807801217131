import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconLock: HelFC<IconPropsInterface> = ({
  width, height, className, color,
}): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-lock"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.75C15.663 8.75 16.2989 9.01339 16.7678 9.48223C17.2366 9.95107 17.5 10.587 17.5 11.25V16.25C17.5 16.913
       17.2366 17.5489 16.7678 18.0178C16.2989 18.4866 15.663 18.75 15 18.75H5C4.33696 18.75 3.70107 18.4866 3.23223 18.0178C2.76339
        17.5489 2.5 16.913 2.5 16.25V11.25C2.5 10.587 2.76339 9.95107 3.23223 9.48223C3.70107 9.01339 4.33696 8.75 5 8.75V6.25C5 4.92392 5.52678
         3.65215 6.46447 2.71447C7.40215 1.77678 8.67392 1.25 10 1.25C11.3261 1.25 12.5979 1.77678 13.5355 2.71447C14.4732 3.65215 15 4.92392
         15 6.25V8.75ZM10 2.91667C10.8841 2.91667 11.7319 3.26786 12.357 3.89298C12.9821 4.5181 13.3333 5.36594 13.3333 6.25V8.75H6.66667V6.25C6.66667
          5.36594 7.01786 4.5181 7.64298 3.89298C8.2681 3.26786 9.11594 2.91667 10 2.91667ZM15 10.4167H5C4.77899 10.4167 4.56702 10.5045 4.41074
          10.6607C4.25446 10.817 4.16667 11.029 4.16667 11.25V16.25C4.16667 16.471 4.25446 16.683 4.41074 16.8393C4.56702 16.9955 4.77899 17.0833
           5 17.0833H15C15.221 17.0833 15.433 16.9955 15.5893 16.8393C15.7455 16.683 15.8333 16.471 15.8333 16.25V11.25C15.8333 11.029 15.7455
            10.817 15.5893 10.6607C15.433 10.5045 15.221 10.4167 15 10.4167Z"
      fill={color}
    />
  </svg>
);

IconLock.defaultProps = {
  className: '',
  width: '1.25rem',
  height: '1.25rem',
  color: '#999494',
};
