import { useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  AccountStateContext,
  AppModalActionTypes,
  ConfigStateContext,
  CurrentTransactionActionTypes,
  CurrentTransactionStateContext,
} from '../../../../../store';
import { BlockchainPoolInterface } from '../../../../../store/context/states/ListState/interfaces';
import { numberWithCommas } from '../../../../../utils';
import { minEthRequired, SystemIDs } from '../../../../../constants';

export const InitiateFundsInvest = () => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const { data, transactionType } = appModal;
  const {
    transactionState: { currentTransactionValue },
    dispatchTransactionState,
  } = useContext(CurrentTransactionStateContext);
  const { accountState } = useContext(AccountStateContext);
  const { balance } = accountState;
  const currentBalance = balance || {
    stableCoin: {
      value: 0,
      symbol: '',
    },
    eth: {
      value: 0,
      symbol: '',
    },
  };
  const { minInvestmentSize = 0, totalDeposited = 0, poolSize = 0 } = data?.blockchainData as BlockchainPoolInterface;
  const symbol = process.env.REACT_APP_STABLECOIN_SYMBOL || 'USDC';

  // If it is Blended Pool, we need to set the maxDepositAmount to 100 (it doesn't have a pool size, just 100 each transaction)
  const maxDepositAmount = poolSize !== 0 ? poolSize - totalDeposited : 100;

  // State update happens when user changes the transaction type
  useEffect(() => {
    console.log(transactionType);
    // when the modal is opened, we need to reset the transaction value to be 0
    dispatchTransactionState({
      type: CurrentTransactionActionTypes.Update,
      payload: {
        currentTransactionValue: 0,
      },
    });
    // disable the button when we initiated withdraw transaction and input value is 0
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        disabled: true,
      },
    });
  }, [transactionType]);

  // Initial balance validation
  useEffect(() => {
    if (currentBalance.stableCoin.value === 0) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          errorMessage: `Insufficient funds. You have ${numberWithCommas(currentBalance.stableCoin.value)} ${
            currentBalance.stableCoin.symbol
          } in your account`,
          disabled: true,
        },
      });
      return;
    }

    if (currentBalance.eth.value < minEthRequired) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: true,
          errorMessage: `Insufficient funds for gas fees. You have ${numberWithCommas(currentBalance.eth.value)} ${
            currentBalance.eth.symbol
          } in your account`,
          disabled: true,
        },
      });
    }
  }, [accountState]);

  // Validating balance and the value of the input field
  const validate = (value: number) => {
    const isAmountInTheRange = value <= maxDepositAmount && value >= minInvestmentSize;
    const isEnoughStableCoins = value <= currentBalance.stableCoin.value;

    if (value && value > 0) {
      if (!isEnoughStableCoins) {
        dispatchAppModal({
          type: AppModalActionTypes.ModalInfoSet,
          payload: {
            error: true,
            errorMessage: `Insufficient funds. You have ${numberWithCommas(
              currentBalance.stableCoin.value,
            )} ${symbol} in your account`,
            disabled: !isEnoughStableCoins,
          },
        });
        return;
      }
      if (!isAmountInTheRange) {
        // Removing the limit for the Blended Pool
        if (data && data.id !== SystemIDs.BlendedPool) {
          dispatchAppModal({
            type: AppModalActionTypes.ModalInfoSet,
            payload: {
              error: !isAmountInTheRange,
              errorMessage: `Deposit amount is out of allowed range, it should be more then ${numberWithCommas(
                +minInvestmentSize,
              )} and less then ${numberWithCommas(+maxDepositAmount)}`,
              disabled: !isAmountInTheRange,
            },
          });
          return;
        }
      }

      if (data && data.id === SystemIDs.BlendedPool && value < minInvestmentSize) {
        dispatchAppModal({
          type: AppModalActionTypes.ModalInfoSet,
          payload: {
            error: value < minInvestmentSize,
            errorMessage: `Deposit amount should be more then ${numberWithCommas(+minInvestmentSize)}`,
            disabled: value < minInvestmentSize,
          },
        });
        return;
      }
    } else {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: !value,
          errorMessage: !value ? 'You need to enter the required amount of deposit' : '',
          disabled: !value,
        },
      });
      return;
    }

    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        error: false,
        errorMessage: '',
        disabled: false,
      },
    });
  };

  return (
    <div className="transaction-container">
      <div className="modal-input">
        {appModal.errorMessage && <p className="error-message">{appModal.errorMessage}</p>}
        <NumericFormat
          className={`${appModal.error ? 'error' : ''} input`}
          value={currentTransactionValue}
          onValueChange={({ floatValue = 0 }) => {
            validate(floatValue);
            dispatchTransactionState({
              type: CurrentTransactionActionTypes.Update,
              payload: {
                currentTransactionValue: floatValue || 0,
              },
            });
          }}
          placeholder="0 USD"
          suffix={` ${symbol}`}
          thousandSeparator
        />
        <p
          className="transaction-modal__system-message"
          style={{
            padding: '0',
            marginTop: '8px',
          }}
        >
          {' '}
          Minimum deposit {numberWithCommas(minInvestmentSize)} {symbol}
        </p>
      </div>
    </div>
  );
};
