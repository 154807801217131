import { useCallback, useContext, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Helmet } from 'react-helmet';
import { DiscordBox, InvestmentCardBox, PageTitleBox, PoolCardBox } from '../modules';
import { HelElement, HelFC } from '../interfaces';
import poolLogo from '../assets/images/Pool.png';
import { ListStateContext, TransactionStateContext } from '../store';
import { PoolStatusTypes, REGIONS, RegionTypes } from '../constants';

export const HeliosPools: HelFC = (): HelElement => {
  const { account } = useWeb3React();
  const [activeRegion, setActiveRegion] = useState<string>(RegionTypes.All);
  const {
    listState: { pools = [] },
    listState,
  } = useContext(ListStateContext);
  const { transactionState } = useContext(TransactionStateContext);
  const filteredPools = useMemo(() => {
    const activePools = pools.filter(pool => pool.status === PoolStatusTypes.Active);
    const closedPools = pools.filter(pool => pool.status === PoolStatusTypes.Closed);
    const investedPools = pools.filter(pool => !!pool.blockchainAccountData?.investedAmount);

    const currentPools =
      activeRegion !== RegionTypes.All ? activePools.filter(pool => pool.region === activeRegion) : activePools;

    return {
      activePools,
      closedPools,
      investedPools,
      currentPools,
    };
  }, [listState, activeRegion, transactionState]);

  const { closedPools, investedPools, currentPools } = filteredPools;

  const getActivePoolsForRegion = useCallback(() => {
    if (activeRegion === RegionTypes.All) {
      return currentPools;
    }
    return currentPools.filter(pool => pool.region === activeRegion);
  }, [activeRegion]);

  const handleRegionClick = (region: string) => () => {
    setActiveRegion(region);
  };

  return (
    <>
      <Helmet>
        <title>Helios-Invest</title>
        <meta
          name="description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />

        <meta property="og:url" content="https://invest.helios.eco" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Helios-Invest" />
        <meta
          property="og:description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />
        <meta
          property="og:image"
          content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v3/invest.helios.eco/Helios%20-%20The%20Evolution%20of%20Solar/https%3A%2F%2Finvest.helios.eco%2FLogo.png/og.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="invest.helios.eco" />
        <meta property="twitter:url" content="https://invest.helios.eco" />
        <meta name="twitter:title" content="Helios-Invest" />
        <meta
          name="twitter:description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />
        <meta
          name="twitter:image"
          content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v3/invest.helios.eco/Helios%20-%20The%20Evolution%20of%20Solar/https%3A%2F%2Finvest.helios.eco%2FLogo.png/og.png"
        />
      </Helmet>
      {!account ? (
        <PageTitleBox
          title="Invest Alongside Climate Experts"
          description="We source our solar projects from top developers around the world, reducing risk while maximizing impact and return."
          logo={poolLogo}
        />
      ) : (
        <>
          {investedPools.length ? (
            <div className="filled-investments">
              <div className="investments">
                <div className="title-description">
                  <h2>My Investments</h2>
                </div>
                <InvestmentCardBox pools={investedPools} />
              </div>
            </div>
          ) : (
            <div className="investments">
              <div className="empty-investments">Build your solar portfolio!</div>
            </div>
          )}
        </>
      )}
      <div className="available-pools" id="availablePools">
        <div className="title-description">
          <h2>Available pools</h2>
          {!account && (
            <p className="description">
              Projects are grouped geographically, ensuring your capital flows where it’s needed most. You can easily
              build a diversified portfolio by funding multiple pools spanning the entire globe.
            </p>
          )}
        </div>
        {!account && (
          <div className="region-filter">
            {REGIONS.map((region, index) => (
              <div key={index}>
                <button
                  className={`${activeRegion === region ? 'active-region' : ''}`}
                  onClick={handleRegionClick(region)}
                >
                  {region}
                </button>
              </div>
            ))}
          </div>
        )}
        <PoolCardBox pools={getActivePoolsForRegion()} />
      </div>
      {!!closedPools.length && (
        <div className="closed-pools">
          <div className="title-description">
            <h2>Closed pools</h2>
            <p className="description">
              These pools have been closed and are no longer accepting investments.
              <br />
              You can still view the details of the pools and the returns they generated.
            </p>
          </div>
          <PoolCardBox pools={closedPools} />
        </div>
      )}
      <DiscordBox />
    </>
  );
};
