import { HelElement, HelFC } from '../../interfaces';
import { CardBaseProps } from './interfaces';
import logo from '../../assets/images/Logo-other.png';
import { ButtonBase, IconDiscord } from '../../components';
import { CategoryTypes, DISCORD_LINK } from '../../constants';

export const DiscordCard: HelFC<CardBaseProps> = ({
  title, description, className,
}): HelElement => (
  <div role="presentation" className={`discord-card ${className || ''}`}>
    <img src={logo} alt="discord" />
    <div className="discord-info">
      <h2>{title}</h2>
      <span className="description">{description}</span>
      <a href={DISCORD_LINK} target="_blank" rel="noreferrer"><ButtonBase className="button-with-icon" label="Join on Discord" category={CategoryTypes.Outlined}><IconDiscord /></ButtonBase></a>
    </div>
  </div>
);
