import { useContext } from 'react';
import { CurrentTransactionActionTypes, CurrentTransactionStateContext } from '../../../CurrentTransactionState';
import { AppModalActionTypes } from '../actions';
import { ConfigStateContext } from '../../ConfigState';

export const useCloseAppModal = () => {
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { dispatchTransactionState } = useContext(CurrentTransactionStateContext);

  return () => {
    dispatchTransactionState({ type: CurrentTransactionActionTypes.Reset });
    dispatchAppModal({ type: AppModalActionTypes.Close });
  };
};
