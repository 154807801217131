import { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLoader } from '../AppLoader';
import { AppModal } from '../../layouts/AppModal';
import { AppConfigActionTypes, ConfigStateContext } from '../../../store';
import { HelFC } from '../../../interfaces';
import { ScrollToTop } from '../../../components';
import { ListDataHandler } from '../../../store/DataHandlers/ListDataHandler';

type MainContainerProps = {
  preloader: ()=> void;
  app: HelFC;
};

export const MainContainer:HelFC<MainContainerProps> = ({ app: CurrentApp, preloader }) => {
  preloader();
  const { appConfig: { isAppPreloaded, isReady }, dispatchAppConfig } = useContext(ConfigStateContext);
  const { getBlockchainPoolList } = ListDataHandler();

  useEffect(() => {
    if (isAppPreloaded) {
      getBlockchainPoolList().then(() => {
        dispatchAppConfig({ type: AppConfigActionTypes.AppConfigSetIsReady, payload: true });
      });
    }
  }, [isAppPreloaded]);

  return (
    <Router>
      <AppLoader />
      {
        isReady && (
          <>
            {/* TODO: Recreate the mess with zustand or something similar */}
            <AppModal />
            <ScrollToTop />
            <CurrentApp />
          </>
        )
      }
    </Router>
  );
};
