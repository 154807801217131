import { useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
  AccountActionTypes,
  AccountStateContext,
  AppConfigActionTypes,
  AppLoaderActionTypes,
  ConfigStateContext,
  TransactionActionTypes,
  TransactionStateContext,
} from '../../../store';
import { ListDataHandler } from '../../../store/DataHandlers/ListDataHandler';
import { PlatformDataHandler } from '../../../store/DataHandlers/PlatformDataHandler';
import { useConnectWallet } from '../../../services/blockChain';

export const AppPreloader = () => {
  const { dispatchAppConfig } = useContext(ConfigStateContext);
  const { dispatchTransactionState } = useContext(TransactionStateContext);
  const { dispatchAccountState } = useContext(AccountStateContext);

  const { getProjectList, getPoolList } = ListDataHandler();
  const { account } = useWeb3React();
  const { connectWallet } = useConnectWallet();

  useEffect(() => {
    dispatchTransactionState({
      type: TransactionActionTypes.GetTransactionsLocal,
    });
    dispatchAccountState({
      type: AccountActionTypes.GetAccountLocal,
    });
    if (!account && localStorage.getItem('isWalletConnected') === 'true') {
      connectWallet()
        .then(() => {})
        .catch((ex: any) => {
          console.log('connection error:', ex);
        });
    }
    Promise.all([getPoolList(), getProjectList()])
      .then(() => {
        dispatchAppConfig({ type: AppConfigActionTypes.AppConfigSetAppPreloaded, payload: true });
      })
      .catch(error => {
        console.log('List data getting Error:', error);
      });
  }, []);
};

export const Preloader = () => {
  const { dispatchAppConfig, dispatchAppLoader } = useContext(ConfigStateContext);
  const { getPlatform } = PlatformDataHandler();

  useEffect(() => {
    Promise.resolve(getPlatform())
      .then(() => {
        dispatchAppConfig({ type: AppConfigActionTypes.AppConfigSetIsReady, payload: true });
        dispatchAppLoader({ type: AppLoaderActionTypes.Close });
      })
      .catch(error => {
        dispatchAppLoader({ type: AppLoaderActionTypes.Close });
        console.log('Platform Data or main lists getting Error:', error);
      });
  }, []);
};
