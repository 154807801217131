import { useContext, useReducer } from 'react';
import { AccountDataHandlerInterface } from './interfaces';
import { dataStateReducer } from '../reducer';
import { requestInitialState } from '../initialDataState';

import { httpRequest } from '../../../services';
import { ACCOUNT_API, RequestTypes } from '../../../constants';
import { DataHandlerActionTypes } from '../interfaces';
import { AccountActionTypes, AccountStateContext } from '../../context/states/AccountState';

export const AccountDataHandler = (): AccountDataHandlerInterface => {
  const [requestState, setRequestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const { dispatchAccountState } = useContext(AccountStateContext);

  const identifyAddress = async (address: string, inquiryId: string) => {
    try {
      await httpRequest({
        url: ACCOUNT_API.SET_ACCOUNT,
        method: RequestTypes.Post,
        withoutToken: true,
        data: { address, inquiryId },
      });
      dispatchAccountState({
        type: AccountActionTypes.SetAccount,
        payload: { isVerified: true },
      });
    } catch (e) {
      dispatchAccountState({
        type: AccountActionTypes.SetAccount,
        payload: { isVerified: false },
      });
    }
  };

  const checkAddressIsIdentified = async (address: string) => {
    try {
      await httpRequest({
        url: `${ACCOUNT_API.SET_ACCOUNT}/${address}/status`,
        method: RequestTypes.Get,
        withoutToken: true,
      });
      dispatchAccountState({
        type: AccountActionTypes.SetAccount,
        payload: { isVerified: true },
      });
    } catch (error: any) {
      console.log('Account validation check:', error.response?.data?.detail);
      dispatchAccountState({
        type: AccountActionTypes.SetAccount,
        payload: { isVerified: false },
      });
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  return {
    requestState,
    identifyAddress,
    checkAddressIsIdentified,
  };
};
