import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { Helmet } from 'react-helmet';
import { ArticleBox, DiscordBox, PoolInfoBox, PoolNameBox, ProjectCardBox } from '../modules';
import { HelElement, HelFC } from '../interfaces';
import { ListStateContext } from '../store';
import { BlockchainPoolInterface, PoolInterface } from '../store/context/states/ListState/interfaces';
import { useMediaQuery } from '../utils';
import { BlockchainDataHandler } from '../store/DataHandlers/BlockchainDataHandler';
import { ParsedTransactionInterface } from '../store/DataHandlers/BlockchainDataHandler/interfaces';
import { TransactionCardBox } from '../modules/boxes/TransactionCardBox';
import { SystemIDs } from '../constants';
import bpCover from '../assets/images/bp-cover.jpg';

export const HeliosPool: HelFC = (): HelElement => {
  const params = useParams();
  const { account } = useWeb3React();
  const {
    listState: { pools = [] },
    listState,
  } = useContext(ListStateContext);
  const [txHistory, setTxHistory] = useState<ParsedTransactionInterface[]>();
  const [pool, setPool] = useState(pools.find(({ id }) => id === params.id) || ({} as PoolInterface));
  const { getRepaymentList, getAccountPool } = BlockchainDataHandler();
  const isTablet = useMediaQuery('(max-width: 860px)');
  const isInvested = !!pool?.blockchainAccountData?.investedAmount;
  // Update pool data on the 'pools' list change
  useEffect(() => {
    setPool(listState.pools?.find(({ id }) => id === params.id) || ({} as PoolInterface));
  }, [listState]);

  // Get repayment list on pool change
  useEffect(() => {
    const { poolAddress } = pool.blockchainData as BlockchainPoolInterface;
    if (getRepaymentList) {
      getRepaymentList(poolAddress).then(history => {
        setTxHistory(history);
      });
    }
  }, [pool]);

  // Get account pool data on account change
  useEffect(() => {
    if (getAccountPool) {
      getAccountPool(pool.id as string)
        .then(blockchainAccountData => {
          setPool({ ...pool, blockchainAccountData });
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [account]);

  return (
    <>
      <Helmet>
        <title>Helios-Invest</title>
        <meta
          name="description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />

        <meta property="og:url" content="https://invest.helios.eco" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Helios-Invest" />
        <meta
          property="og:description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />
        <meta
          property="og:image"
          content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v3/invest.helios.eco/Helios%20-%20The%20Evolution%20of%20Solar/https%3A%2F%2Finvest.helios.eco%2FLogo.png/og.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="invest.helios.eco" />
        <meta property="twitter:url" content="https://invest.helios.eco" />
        <meta name="twitter:title" content="Helios-Invest" />
        <meta
          name="twitter:description"
          content="Take a part in the building a sustainable future for all of us. Helios is a platform that connects people who want to make a difference with the organizations that need their help."
        />
        <meta
          name="twitter:image"
          content="https://ogcdn.net/e4b8c678-7bd5-445d-ba03-bfaad510c686/v3/invest.helios.eco/Helios%20-%20The%20Evolution%20of%20Solar/https%3A%2F%2Finvest.helios.eco%2FLogo.png/og.png"
        />
      </Helmet>
      <div className="pool-image">
        {pool.images?.length ? (
          <>
            <div className="overlay" />
            <img
              className={`${pool.id === SystemIDs.BlendedPool ? 'object-bottom' : 'object-center'}`}
              src={pool.id === SystemIDs.BlendedPool ? bpCover : pool.images?.[0]?.urls?.original}
              alt="story 1"
            />
          </>
        ) : (
          <div className="skeleton" />
        )}
      </div>

      <div className="app-content">
        <div className="left-section">
          {!isTablet && <PoolNameBox name={pool.name} overview={pool.overview} />}
          <ArticleBox item={pool} />
          {!!pool.projects?.length && <ProjectCardBox projects={pool.projects} poolName={pool.name} />}
          {!!txHistory?.length && <TransactionCardBox title="Repayment History" items={txHistory} />}
          <DiscordBox />
        </div>
        {/* Adjusting the height since we have more fields in the Pool Info Box */}
        <div className="right-section" style={{ top: `${isInvested ? '1rem' : '8rem'}` }}>
          {isTablet && <PoolNameBox name={pool.name} overview={pool.overview} />}
          <PoolInfoBox pool={pool} pool_status={pool.status} />
        </div>
      </div>
    </>
  );
};
