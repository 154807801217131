import { useContext, useEffect, useState } from 'react';
import Persona from 'persona';
import { useWeb3React } from '@web3-react/core';
import { HashLink as Link } from 'react-router-hash-link';
import { HelElement, HelFC } from '../../interfaces';
import { ButtonBase, PoolBalanceProgressBlock } from '../../components';
import { PoolInfoBoxProps } from './interfaces';
import { CategoryTypes, CurrentTransactionType, PoolStatusTypes, SystemIDs } from '../../constants';
import { AccountStateContext, AppModalActionTypes, ConfigStateContext } from '../../store';
import { PoolInterface } from '../../store/context/states/ListState/interfaces';
import {
  PoolBlockchainAccountValues,
  PoolBlockchainValues,
} from '../../store/DataHandlers/BlockchainDataHandler/interfaces';
import { PoolInfoBlock } from '../../components/blocks/PoolInfoBlock';
import { AccountDataHandler } from '../../store/DataHandlers/AccountDataHandler';
import { ActionButtons } from '../modals/shared/ActionButtons';
import { lockupPeriodSM, withdrawInvestmentSM } from '../../constants/systemMessages';
import { isMetaMaskInstalled, numberWithCommas } from '../../utils';
import { useWeb3Connection } from '../../services/blockChain/hooks/useWeb3Connection';

export const PoolInfoBox: HelFC<PoolInfoBoxProps> = ({
  className,
  pool = {} as PoolInterface,
  pool_status,
}): HelElement => {
  const { account } = useWeb3React();
  const [isDetailsBlockHidden, setIsDetailsBlockHidden] = useState(true);
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { establishConnection } = useWeb3Connection();
  const { identifyAddress } = AccountDataHandler();

  const {
    annualEmissionsAverted = 0,
    annualSolarEnergyProduced = 0,
    blockchainData = {} as PoolBlockchainValues,
    blockchainAccountData = {} as PoolBlockchainAccountValues,
    apy,
  } = pool;
  const { lockupPeriod, poolSize = 0, totalDeposited = 0, minInvestmentSize = 0 } = blockchainData;
  const { investedAmount = 0, accountPoolBalance = 0, yearnedYield = 0, isInvested } = blockchainAccountData;

  const {
    accountState: { isVerified },
  } = useContext(AccountStateContext);

  useEffect(() => {
    if (!account) {
      setIsDetailsBlockHidden(false);
    } else {
      setIsDetailsBlockHidden(true);
    }
  }, [account]);

  // If the pool size is less than the total deposited amount, the pool is closed by the admin
  const isPoolClosedByAdmin = pool_status === PoolStatusTypes.Closed && poolSize > totalDeposited;

  // If the pool size is equal to the total deposited amount, the pool has reached the goal
  const isPoolReachedTheGoal =
    pool_status === PoolStatusTypes.Closed && pool.blockchainData?.totalDeposited === pool.blockchainData?.poolSize;

  const toggleDetailsBlock = () => {
    setIsDetailsBlockHidden(!isDetailsBlockHidden);
  };
  const openInvestModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        title: 'Invest funds',
        transactionType: CurrentTransactionType.InvestFunds,
        data: pool,
        info: {
          systemMessage: lockupPeriodSM,
        },
      },
    });
  };

  const openYieldInvestModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        title: 'Invest Yield',
        transactionType: CurrentTransactionType.InvestYield,
        data: pool,
        info: {
          systemMessage: lockupPeriodSM,
        },
      },
    });
  };

  const openWithdrawInvestmentModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        data: pool,
        /* Transaction type is required to pick the right transaction steps */
        transactionType: CurrentTransactionType.WithdrawInvestment,
        info: {
          systemMessage: withdrawInvestmentSM,
        },
      },
    });
  };
  const openWithdrawYieldModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.TransactionModal,
      payload: {
        data: pool,
        /* Transaction type is required to pick the right transaction steps */
        transactionType: CurrentTransactionType.WithdrawYield,
        info: {
          bonusAction: {
            label: 'Withdraw your investment from Helios?',
            onClick: () => {
              openWithdrawInvestmentModal();
            },
          },
          systemMessage:
            'Please note that funds are locked up for a period of 90 days before you are able to withdraw. Subsequent deposits will reset the timer based on a weighted average calculation between your initial and subsequent deposits.',
        },
      },
    });
  };
  const openWithdrawModal = () => {
    /* If we have yield to withdraw, steps should be for Withdraw Yield, if not - show Withdraw Investment steps. */
    /* Steps are defined inside TransactionModal component based on the result of useModalSteps custom hook */
    if (yearnedYield > 0) {
      openWithdrawYieldModal();
    } else {
      openWithdrawInvestmentModal();
    }
  };

  const openPersonaModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        disabled: true,
      },
    });
    const environmentId = process.env.REACT_APP_PERSONA_ENVIRONMENT_ID!;
    const client: any = new Persona.Client({
      templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
      environmentId,
      referenceId: account as string,
      onCancel: () => {
        dispatchAppModal({ type: AppModalActionTypes.Close });
      },
      onError: () => {
        dispatchAppModal({ type: AppModalActionTypes.Close });
      },
      onComplete: ({ inquiryId: inqId }) => {
        identifyAddress(account as string, inqId).then(() => {
          openInvestModal();
        });
      },
    });

    dispatchAppModal({ type: AppModalActionTypes.Close });
    client.open();
  };

  const openValidationInformModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.InformModal,
      payload: {
        content: (
          <div className="inform-content">
            <span className="title">Dear valued customer,</span>
            <span>
              To ensure the security and compliance of our services, we kindly request that all customers undergo KYC
              verification.
            </span>
            <span>
              This process is a standard practice in the financial industry and is required by law to prevent fraudulent
              activities.
            </span>
            <span>
              We appreciate your cooperation and assure you that your personal information will be kept confidential and
              secure.
            </span>
            <ActionButtons
              items={[
                {
                  category: CategoryTypes.Filled,
                  label: 'Ok',
                  callBack: openPersonaModal,
                },
                {
                  category: CategoryTypes.Outlined,
                  label: 'Cancel',
                  callBack: () => {
                    dispatchAppModal({ type: AppModalActionTypes.Close });
                  },
                },
              ]}
            />
          </div>
        ),
        cancelCallBack: () => {
          dispatchAppModal({ type: AppModalActionTypes.Close });
        },
      },
    });
  };

  const openMetamaskModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.MessageModal,
      payload: {
        title: 'MetaMask is not installed',
        description: 'Please install metamask extension to connect to the blockchain.',
        action: (
          <p className="pt-3">
            <a
              href="https://metamask.io/download.html"
              target="_blank"
              rel="noreferrer"
              className="underline text-pink-light hover:text-pink-strong"
            >
              Install MetaMask
            </a>
          </p>
        ),
      },
    });
  };

  const initiateInvestment = async () => {
    // Directly return if MetaMask is not installed
    if (!isMetaMaskInstalled()) {
      openMetamaskModal();
      return;
    }

    // If MetaMask is installed but no account is connected, try to establish connection
    if (!account) {
      await establishConnection(); // establishConnection handles all scenarios after attempting connection
      return;
    }

    // If the account is connected, check if the user is identified with Persona (KYC)
    if (isVerified) {
      openInvestModal(); // Open investment modal if the user is already identified
    } else {
      openValidationInformModal(); // Open validation modal if the user is not identified
    }
  };

  const mainInfoUser = (
    <div className="flex w-[100%] text-pink-light">
      <div className="flex-1 flex flex-col gap-3 justify-center items-center">
        <span className="font-primary-regular text-[0.75rem]">30-Day APY</span>
        <span className="leading-4">
          <h4 className="mr-[0.125rem]">{`${apy || 0}%`}</h4>
        </span>
      </div>
      <div className="w-[1px] h-100 bg-pink-strong" />
      <div className="flex-1 flex flex-col gap-3 justify-center items-center">
        <span className="font-primary-regular text-[0.75rem]">Emissions Averted</span>
        <span className="leading-4">
          <h4 className="mr-[0.125rem]">{`${numberWithCommas(annualEmissionsAverted) || 0} kg`}</h4>
        </span>
      </div>
    </div>
  );

  const mainInfoInvestor = (
    <div className="flex w-[100%] text-pink-light">
      <div className="flex flex-col gap-1 justify-between items-start pl-[.5rem] pr-14">
        <span className="font-primary-regular text-[12px] ml-[-2px]">Deposit</span>
        <span className="leading-4">
          <h4>{`${numberWithCommas(investedAmount) || 0}`}</h4>
        </span>
      </div>
      <div className="w-[1px] h-100 bg-pink-strong" />
      <div className="flex flex-col gap-1 justify-between items-start pl-14">
        <span className="font-primary-regular text-[12px] ml-[-2px]">Yield</span>
        <span className="leading-4">
          <h4>{`${numberWithCommas(yearnedYield) || 0}`}</h4>
        </span>
      </div>
    </div>
  );

  return (
    <div className={`pool-info-box ${className || ''}`}>
      <div className="pool-main-info">
        {/* Blended Pool does not have pool size */}
        {/* We display progress bar when: wallet is not connected, or pool is not Blended Pool or when the wallet is connected and the investment size is zero */}
        {!account && pool.id !== SystemIDs.BlendedPool ? (
          <PoolBalanceProgressBlock value={poolSize as number} currentValue={totalDeposited} prefix="$" />
        ) : (
          accountPoolBalance > 0 && (
            <>
              <div className="balance">
                <span className="label">Balance</span>
                <span className="value">
                  <h2>${numberWithCommas(accountPoolBalance)}</h2>
                </span>
              </div>
              {mainInfoInvestor}
            </>
          )
        )}
        {accountPoolBalance && account ? (
          // if the pool is empty, disable withdraw action
          <ButtonBase
            className="button-without-icon"
            label="Withdraw"
            category={CategoryTypes.Filled}
            onClick={openWithdrawModal}
            disabled={pool.blockchainData?.totalDeposited ? pool.blockchainData?.totalDeposited === 0 : false}
          />
        ) : (
          <>
            {pool_status === PoolStatusTypes.Closed ? (
              <div className="text-[#5a619d] text-sm font-primary-light text-center">
                <p>This pool {isPoolReachedTheGoal ? 'has reached its full capacity.' : 'has been closed by admin.'}</p>
                <p>
                  Check{' '}
                  <Link to="/pools#availablePools" className="!underline cursor-pointer text-pink-strong">
                    available pools
                  </Link>{' '}
                  for investment.
                </p>
              </div>
            ) : (
              <ButtonBase
                className="button-without-icon"
                label="Invest Today"
                category={CategoryTypes.Filled}
                onClick={initiateInvestment}
              />
            )}
          </>
        )}
        {(!isInvested || !account) && mainInfoUser}
        {account && isInvested ? (
          <span className="hide-details" onClick={toggleDetailsBlock}>
            {isDetailsBlockHidden ? 'View Details' : 'Hide details'}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div
        className="pool-info"
        style={{
          display: `${isDetailsBlockHidden && investedAmount ? 'none' : 'block'}`,
        }}
      >
        {isInvested && account && <PoolInfoBlock label="30-Day APY" value={apy} suffix="%" />}
        <PoolInfoBlock label="Hold Period" value={lockupPeriod} suffix="day(s)" />
        <PoolInfoBlock label="Minimum Investment Amount" value={minInvestmentSize} prefix="$" />
        <PoolInfoBlock label="Energy Produced" value={annualSolarEnergyProduced} suffix="kWh" />
      </div>

      {!!(investedAmount || yearnedYield) && account && (
        <div className="reinvest">
          {/* Blended pool does not have pool size */}
          {pool.id !== SystemIDs.BlendedPool && (
            <PoolBalanceProgressBlock value={poolSize as number} currentValue={totalDeposited} prefix="$" />
          )}
          <div className="text-[#5a619d] text-sm font-primary-light">
            {isPoolReachedTheGoal && <div>This pool has reached its full capacity</div>}
            {isPoolClosedByAdmin && <div>This pool has been closed by the admin</div>}
          </div>
          {isPoolReachedTheGoal || isPoolClosedByAdmin ? (
            <Link to="/pools#availablePools" className="!w-full">
              <ButtonBase
                className="button-without-icon"
                label="Check available pools"
                category={CategoryTypes.Outlined}
              />
            </Link>
          ) : (
            <ButtonBase
              className="button-without-icon"
              label="Add Funds"
              category={pool_status === PoolStatusTypes.Closed ? CategoryTypes.Disabled : CategoryTypes.Outlined}
              onClick={openInvestModal}
              disabled={pool_status === PoolStatusTypes.Closed}
            />
          )}
          {/* threshold validation is conducted inside the BP Invest Yield component, but we need to let user know he can invest yield into BP when he has yield */}
          {yearnedYield > 0 && (
            <span className="underline text-pink-strong text-sm cursor-pointer" onClick={openYieldInvestModal}>
              Invest Yield into Blended Pool
            </span>
          )}
        </div>
      )}
    </div>
  );
};
