import { IconLock } from '../icons/IconLock';

interface LockedValueProps {
  value: string;
  iconClasses?: string;
  valueClasses?: string;
}
export const LockedValue = ({ value, iconClasses, valueClasses } : LockedValueProps) => (
  <div className="locked-value__container">
    <div className={`locked-value__icon ${iconClasses}`}>
      <IconLock />
    </div>
    <div className={`locked-value__value ${valueClasses}`}>
      <span>{value}</span>
    </div>
  </div>
);

LockedValue.defaultProps = {
  iconClasses: '',
  valueClasses: '',
};
