import React, { useContext } from 'react';
import { ModalTypes } from '../../constants';
import { ModalBase } from './ModalBase';
import { ConfigStateContext } from '../../store';
import { InfoModal } from './InfoModal';
import { TransactionModal } from './TransactionModal/TransactionModal';

export const ModalWrapper:React.FC = () => {
  const { appModal } = useContext(ConfigStateContext);

  switch (appModal.modalType) {
    case ModalTypes.DepositModal:
      return <ModalBase className="modal-large" />;
    case ModalTypes.MessageModal:
      return <ModalBase className="modal-small" />;
    case ModalTypes.InformModal:
      return <ModalBase className="modal-small inform-modal" />;
    case ModalTypes.InfoModal:
      return (
        <InfoModal
          {...appModal.info}
          type={appModal.info?.type}
          description={appModal.info?.description}
          label={appModal.info?.label}
        />
      );
    case ModalTypes.TransactionModal:
      return (
        <TransactionModal />
      );
    default:
      return <ModalBase />;
  }
};
