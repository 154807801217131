import { useContext } from 'react';
import Lottie from 'lottie-react';
import { ConfigStateContext } from '../../../store';
import { HelFC } from '../../../interfaces';
import animationData from '../../../lotties/swirl.json';

export const AppLoader: HelFC = () => {
  const { appLoader } = useContext(ConfigStateContext);
  if (appLoader.isOpen) {
    return (
      <div className="app-loader">
        <div className="loader-wrapper">
          {!!appLoader.description && <h4>{appLoader.description}</h4>}
          <div className="w-[48px] h-[48px]">
            <Lottie animationData={animationData} loop autoplay />
          </div>
        </div>
      </div>
    );
  }

  return null;
};
