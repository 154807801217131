import { HelElement, HelFC } from '../../interfaces';

import { PoolNameBoxProps } from './interfaces';

export const PoolNameBox: HelFC<PoolNameBoxProps> = ({
  name,
  overview,
  className,
}): HelElement => (
  <div className={`pool-name-box ${className || ''}`}>
    <h2>{name}</h2>
    <span className="description">
      {overview}
    </span>
  </div>

);
