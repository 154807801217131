import { HelElement, HelFC } from '../../interfaces';
import { PoolInfoBlockProps } from './interface';
import { numberWithCommas } from '../../utils/formatting';

export const PoolInfoBlock:HelFC<PoolInfoBlockProps> = ({
  label, value, prefix, suffix,
}): HelElement => (
  <div className="pool-info-block">
    <span className="label">{label}</span>
    <span className="value-block">
      {prefix && <span className="prefix">{prefix}</span>}
      {(value || value === 0) && <span className="value">{numberWithCommas(value)}</span>}
      {suffix && <span className="suffix">{suffix}</span>}
    </span>
  </div>
);
